<template>

  <body>
    <!-- <div class="topbar">
      <div class="img-top">
        <img :src="require('../assets/newImages/logo.png')" alt="" />
      </div>
    </div> -->
    <section class="bg-login">
      <div class="container">
        <div class="">
          <div class="main-part-log row">
            <div class="col-lg-12 text-center mb-4">
                  <img class="mb-5"
                    :src="require('../assets/img/login-logo.png')"
                    alt=""
                  />
            </div>
            <div class="col-lg-12 col-right-login">
              <div class="px-5">
                <div class=" title">
                  <div class="signin-title mb-2">Sign in</div>
                  <div class="log-head ">Log Into Your Account:</div>
                </div>
                <argon-alert color="warning" v-if="error" v-html="error"></argon-alert>
                <div class="form-group w-100 position-relative mb-4">
                  <span class="icon">
                    <img src="../assets/img/email.svg" alt="">
                  </span>
                  <input type="text" :value="email" id="email" name="email" class="form-control "
                    placeholder="Email" v-on:keyup.enter="nextInputFocus()" />
                </div>
                <div class="form-group w-100 position-relative mb-4">
                  <span class="icon">
                    <img src="../assets/img/password.svg" alt="">
                  </span>
                  <input type="password" :value="password" id="password" name="password" class="form-control "
                    placeholder="Password" v-on:keyup.enter="clickSignIn()" />
                </div>
                <div class="sub-log">
                  <button class="submit-btn-login mb-3" @click="Login" :disabled="isProcessing">
                    Sign In
                  </button>
                </div>
              </div>
            </div>

            <div class="col-12 text-center sign-up-col"> 
              <div class="signup">Don't have an account yet?  <a href="#" class="">Sign up</a></div>
              <div class="signup mt-3"><a href="#" class="">Forgot Your Password?</a></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="bottom-bar-log">
      <p>Powered By Viribuz Media</p>
    </div> -->
  </body>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";

const body = document.getElementsByTagName("body")[0];
export default {
  name: "Login",
  components: {
  //   ArgonInput,
  //   ArgonSwitch,
  //   ArgonButton,
    ArgonAlert,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref(null);
    const isProcessing = ref(false);

    const store = useStore();
    const router = useRouter();

    const Login = async () => {
      isProcessing.value = true;
      try {
        let logIn = await store.dispatch("logIn", {
          email: body.querySelector("#email").value,
          password: body.querySelector("#password").value,
        });
        console.log("logIn", logIn);
        isProcessing.value = false;
        router.push({ name: "Dashboard" });
      } catch (err) {
        isProcessing.value = false;
        const errorMessage = err.message.replace(
          "FirebaseError: Firebase: ",
          ""
        );
        error.value = err.message.replace("FirebaseError: Firebase:", "");
        if (errorMessage === "Error (auth/wrong-password).") {
          error.value = "Incorrect password";
        } else if (errorMessage === "Error (auth/user-not-found).") {
          error.value = "This email does not exist in the system.";
        }
      }
    };

    const nextInputFocus = () => {
      document.querySelector('#password').focus();
    };

    const clickSignIn = () => {
      document.querySelector('.submit-btn-login').click();
    };

    return { Login, email, password, error, nextInputFocus, clickSignIn };
  },
};
</script>
