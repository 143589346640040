import { createStore } from "vuex";
import { auth, db } from "./firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    contacts: [],
    view:''
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_VIEW(state, value) {
      state.view = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_CONTACTS(state, data) {
      state.contacts = [...data];
    },
    SET_LOCATIONS(state, data) {
      state.locations = [...data];
    },
  },
  actions: {
    async register({ email, password, name, roleId, locationId }) {
      try {
        const response = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        if (response) {
          response.user.updateProfile({ displayName: name });
          collection(db, "profiles").add({
            full_name: name,
            email,
            avatar: "",
            user_id: response.user_id,
          });
          collection(db, "user_roles").add({
            role_id: roleId,
            user_id: response.user_id,
          });

          for (let i = 0; i < locationId.length; i++) {
            const location_id = locationId[i];
            collection(db, "user_locations").add({
              location_id,
              user_id: response.user_id,
            });
          }
        } else {
          throw new Error("Unable to register user");
        }
      } catch (err) {
        throw new Error(err);
      }
    },

    async logIn(context, { email, password }) {
      console.log(auth);
      try {
        const response = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        console.log("response :", response);
        if (response) {
          const q = query(
            collection(db, "user_roles"),
            where("user_id", "==", response.user.uid)
          );
          const querySnap = await getDocs(q);

          if (querySnap.docs[0]) {
            const user_role = querySnap.docs[0].data();

            const roleQuerySnap = await getDoc(
              doc(db, "roles", user_role.role_id)
            );
            const role = roleQuerySnap.data();
            response.user.role = {
              id: user_role.role_id,
              name: role.name,
            };
          }
          const location_q = query(
            collection(db, "user_locations"),
            where("user_id", "==", response.user.uid)
          );
          const locationQuerySnap = await getDocs(location_q);

          if (locationQuerySnap.docs) {
            const locations = [];
            locationQuerySnap.docs.forEach((doc) => {
              const user_location = doc.data();
              locations.push = user_location.location_id;
            });
            response.user.locationId = locations;
          }

          const profile_q = query(
            collection(db, "profiles"),
            where("user_id", "==", response.user.uid)
          );
          const profileQuerySnap = await getDocs(profile_q);
          if (profileQuerySnap.docs[0]) {
            const user_profile = profileQuerySnap.docs[0].data();
            response.user.displayName = user_profile.full_name;
            response.user.avatar = user_profile.avatar;
          }

          context.commit("SET_USER", response.user);
          console.log("response.user :", response.user);
          return response;
        } else {
          throw new Error("login failed");
        }
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },

    async logOut(context) {
      await signOut(auth);
      context.commit("SET_USER", null);
    },

    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        const q = query(
          collection(db, "user_roles"),
          where("user_id", "==", user.uid)
        );
        const querySnap = await getDocs(q);

        if (querySnap.docs[0]) {
          const user_role = querySnap.docs[0].data();

          const roleQuerySnap = await getDoc(
            doc(db, "roles", user_role.role_id)
          );
          const role = roleQuerySnap.data();
          user.role = {
            id: user_role.role_id,
            name: role.name,
          };
        }
        const location_q = query(
          collection(db, "user_locations"),
          where("user_id", "==", user.uid)
        );
        const locationQuerySnap = await getDocs(location_q);

        if (locationQuerySnap.docs && locationQuerySnap.docs.length > 0) {
          let locations = [];
          locationQuerySnap.docs.forEach(loc => {
            const user_location = loc.data();
            locations.push(user_location.location_id);
          })
          user.locationId = locations;
        }

        const profile_q = query(
          collection(db, "profiles"),
          where("user_id", "==", user.uid)
        );
        const profileQuerySnap = await getDocs(profile_q);
        if (profileQuerySnap.docs[0]) {
          const user_profile = profileQuerySnap.docs[0].data();
          user.displayName = user_profile.full_name;
          user.avatar = user_profile.avatar;
        }
        context.commit("SET_USER", {
          ...user,
          email: user.email,
        });
      } else {
        context.commit("SET_USER", null);
      }
    },
    async getContacts(context, payload) {
      context.commit("SET_CONTACTS", payload.data);
    },
    async getLocations(context, payload) {
      context.commit('SET_LOCATIONS', payload.data);
    },
    async getView(context, payload) {
      context.commit('SET_VIEW', payload.view);
    }
  }

})

// export the store
export default store;
