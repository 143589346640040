<template>
  <div>
    <highcharts class="hc" :options="getOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from 'highcharts/modules/stock'

exportingInit(Highcharts);
stockInit(Highcharts);

export default {
  props: ["data"],
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          backgroundColor: null,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.data ? Object.keys(this.data) : [],
          labels: {
            style: {
              color: '#fff'
            },
            autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
          },
          min: 0,
          max: 8,
          tickPositions: this.getTickPosition(),
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          color: '#fff',
          labels: {
            style: {
              color: '#fff'
            }
          },
          autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
        },
        legend: {
          reversed: true,
          enabled: false,
        },
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            stacking: "normal",
          },
          bar: {
            borderWidth: 0
          }
        },
        series: [
          {
            name: "",
            data: this.data ? Object.values(this.data) : [],
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#7E51FF'],
                [1, '#D40DFF']
              ]
            }
          },
        ],
      },
    };
  },
  computed: {
    getOptions() {
      return {
        chart: {
          type: "column",
          backgroundColor: null,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.data ? Object.keys(this.data) : [],
          labels: {
            style: {
              color: '#fff'
            }
          },
          min: 0,
          max: 8,
          scrollbar: {
            enabled: true,
            barBackgroundColor: 'gray',
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: 'gray',
            buttonBorderWidth: 0,
            buttonBorderRadius: 7,
            trackBackgroundColor: 'none',
            trackBorderWidth: 0,
            trackBorderRadius: 8,
            trackBorderColor: '#CCC',
            height: 10,
          },
          tickPositions: this.getTickPosition(),
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          color: '#fff',
          labels: {
            style: {
              color: '#fff'
            }
          },
        },
        legend: {
          reversed: true,
          enabled: false,
        },
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            stacking: "normal",
          },
          bar: {
            borderWidth: 0
          }
        },
        series: [
          {
            name: "",
            data: this.data ? Object.values(this.data) : [],
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#7E51FF'],
                [1, '#D40DFF']
              ]
            }
          },
        ],
      }
    }
  },
  methods: {
    getTickPosition: function() {
      return this.data ? Object.keys(this.data).map((item, index) => index) : [];
    }
  }
};
</script>