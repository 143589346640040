<template>
  <div id="dashboard">
    <div class="container-fluid top-0 position-sticky z-index-sticky header">
      <div class="row">
        <div class="col-12">
          <navbar isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" v-bind:darkMode="true"
            isBtn="bg-gradient-success" :user="user" :changeType="changeCampaignType" :changeLocation="changeLocation"
            :showSidebar="toggleSidebar" :headersDropdown="true" :changeTypeValue="changeTypes"
            :showCampaignType="true" />
        </div>
      </div>
    </div>
    <div class="sidebar-container" :class="visibleSidebar ? 'visible-sidebar' : 'hide-sidebar'">
      <div class="sidebar-mask" @click="toggleSidebar()"></div>
      <sidebar :user="user" />
    </div>
    <div class="container">
      <div class="d-flex justify-content-center mt-3">
        <p class="text-white dashboard-greetings">
          <strong>Campaign Performance Dashboard</strong>
        </p>
      </div>
      <div class="main-content px-4 mt-4">
        <div class="d-flex justify-content-between align-items-center px-2">
          <h4 class="text-white">Status</h4>
          <div class="d-flex align-items-center px-2">            
            <select class="form-control period-select" v-on:change="applyDateFilter($event.target.value);">
              <option value="all">All Time</option>
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="custom">Custom</option>
            </select>
            <button class="btn text-white px-5 mx-4" type="button"
              data-bs-toggle="modal" data-bs-target="#manage_stats"
              v-on:click="addNewCategory = true; " style="margin-bottom: 0px; border: 1px solid white; font-weight: normal; min-width:185px; padding:0.40rem 0.75rem;"> Manage Stats
            </button>

            <div id="manage_stats" class="modal fade"  tabindex="-1"
            aria-labelledby="manage_stats" aria-hidden="true" role="dialog" >
              <div class="modal-dialog mt-lg-10" v-on:click="$event.stopPropagation()">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="ModalLabel" class="modal-title">Manage Stats</h5>
                    <button type="button" class="btn-close bg-dark" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_retainers_per_state"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_retainers_per_state"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_retainers_per_state"
                        >Hide Total Retainers per State</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_retainers_record_count"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_retainers_record_count"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_retainers_record_count"
                        >Hide Retainers by Age</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_injured_age"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_injured_age"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_injured_age"
                        >Hide Injured Age</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_claim_outcome"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_claim_outcome"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_claim_outcome"
                        >Hide Claim Outcome</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_disease_type"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_disease_type"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_disease_type"
                        >Hide Disease Type</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_accident_type"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_accident_type"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_accident_type"
                        >Hide Accident Type</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_brand"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_brand"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_brand"
                        >Hide Brand</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_hospital_name"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_hospital_name"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_hospital_name"
                        >Hide Hospital Name</label>
                      </div>
                    </div>

                    <div class="my-2">
                      <div class="form-check form-switch">
                        <input
                          id="option_hide_dq_status"
                          class="form-check-input"
                          type="checkbox"
                          @change="handleManageStats( )"
                          v-model="hide_dq_status"
                        />                        
                        <label
                          class="form-check-label"
                          for="option_hide_dq_status"
                        >Hide DQ Reasons</label>
                      </div>
                    </div>

                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-primary btn-sm modal_close" data-bs-dismiss="modal"
                    >
                    Update
                    </button>
                    <!-- <button class="btn bg-gradient-success btn-sm"
                      v-on:click="handleManageStats()">
                      Submit
                    </button> -->
                  </div>
                  <!-- </form> -->
                </div>
              </div>
            </div>
          </div>
                    
        </div>
        
        
        <div class="d-flex ms-auto w-100 mt-2 px-0 justify-content-between period-select me-2" v-if="showCustomDateSelect">
          <div class="form-group col-5">
            <label for="" class="text-white" >Start Date</label>
            <input type="date" :value="start_date" v-on:change="onChangeStartDate($event)" class="form-control period-select">
          </div>
          <div class="form-group col-5" >
            <label for="" class="text-white">End Date</label>
            <input type="date" :class="start_date == '' ? 'opacity-25':''" :disabled="start_date == ''"  :value="end_date" v-on:change="onChangeEndDate($event)" class="form-control period-select">
          </div>
        </div>
        <main class="mt-3 d-flex flex-wrap align-items-stretch">
          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-primary dashboard-value-card" style="background: rgba(212, 13, 255, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Retainers:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/users.svg" class="avatar avatar-sm mx-1" alt="logo users" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.retainers_count || 0 }}
                </h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-pirmary dashboard-value-card" style="background: rgba(169, 0, 228, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Retaining %:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/call.svg" class="avatar avatar-sm mx-1" alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.retainers_percent || 0 }}%
                </h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-primary dashboard-value-card" style="background: rgba(116, 81, 241, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Turnbacks:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/group.svg" class="avatar avatar-sm mx-1" alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.turnbacks_count || 0 }}
                </h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-danger dashboard-value-card" style="background: rgba(56, 54, 160, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Turnback %:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/send.svg" class="avatar avatar-sm mx-1" alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.turnbacks_percent || 0 }}%
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-danger dashboard-value-card" style="background: rgba(56, 54, 160, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">DQ's:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/calendar.svg" class="avatar avatar-sm mx-1"
                    alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.disqualified_count || 0 }}
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-danger dashboard-value-card" style="background: rgba(0, 0, 0, 0.4)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Net Retainers:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/dislike.svg" class="avatar avatar-sm mx-1" alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.net_retainers_count || 0 }}
                </h3>
              </div>
            </div>
          </div>         

          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-danger dashboard-value-card" style="background: rgba(56, 54, 160, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Leads:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/calendar.svg" class="avatar avatar-sm mx-1"
                    alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ campaignDashboardCounts.leads_count || 0 }}
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-danger dashboard-value-card" style="background: rgba(0, 0, 0, 0.4)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Cost Per Lead:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/dislike.svg" class="avatar avatar-sm mx-1" alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  <!-- {{ campaignDashboardCounts.net_retainers_count || 0 }} -->***
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
            <div class="card card-danger dashboard-value-card" style="background: rgba(56, 54, 160, 0.8)">
              <div style="padding: 25.35px 46.22px; height: 100%" class="d-flex flex-column justify-content-between">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Cost Per Acquisition:</p>
                  </div>
                  <img src="../assets/img/icons/dashboard/send.svg" class="avatar avatar-sm mx-1" alt="logo cross" />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  <!-- {{ campaignDashboardCounts.turnbacks_percent || 0 }}% -->***
                </h3>
              </div>
            </div>
          </div>


        </main>
        
      </div>
    </div>
    <div class="container">
      <div class="row">
        <h4 class="text-white-h" style="padding-left: 50px;margin-top: 45px;">Campaign Statistics</h4>
        <div class="row d-flex align-items-stretch">
          
          <div class="col-lg-6 my-2" v-if="!hide_retainers_per_state">
            <div class="main-content px-4 h-100">
              <div class="p-2 h-100">
                <div class="card card-primary bg-black mt-3 common-bg h-100">
                  <h3 class="chart-card-title">Total Retainers per State</h3>
                  <Chart :data="retainersPerState?.retainers_per_state" v-if="!isLoadingPerState" />
                  <div>
                    <div class="small-loader" v-if="isLoadingPerState">Loading...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-lg-6 my-2" v-if="!hide_retainers_record_count">
            <div class="main-content px-4 h-100">
              <div class="p-2 h-100">
                <div class="card card-primary bg-black mt-3 common-bg h-100">
                  <div class="d-flex justify-content-between">
                    <h3 class="chart-card-title">Total Retainers</h3>
                    <!-- <div class="dropdown">
                      <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                        aria-expanded="false" style="margin-bottom: 0px; color: white; border: 2px solid white;text-align: left;width: 160px;min-width: 50px;">
                        01-07 June 2021
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="max-width: 180px;margin-top: 55px!important;">
                        <li>
                          <a class="dropdown-item text-break" href="#">01-07 June 2021</a>
                          <a class="dropdown-item text-break" href="#">08-14 June 2021</a>
                        </li>
                      </ul>
                    </div> -->
                  </div>
                  <AreaChart :data="retainersByDate?.retainers_per_date" v-if="!isLoadingPerTotal" />
                  <div v-if="!isLoadingPerTotal">
                    <h3 class="chart-card-title mb-0" style="text-align:center;">Record Count: {{ totalRecordsByDate }}</h3>
                  </div>
                  <div>
                    <div class="small-loader" v-if="isLoadingPerTotal">Loading...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-lg-12 my-2" v-if="!hide_injured_age">
          <div class="main-content px-4 mt-4">
            <h4 class="text-white-h">Injured Age</h4>
            <div class="p-2">
              <div class="card card-primary bg-black mt-3 common-bg">
                <h3 class="chart-card-title mb-0">Updated: <span>{{ moment.tz(new Date() ,
                'America/Los_Angeles').format('LL')}}</span></h3>
                <ColumChart v-if="!isLoadingPerAge" :data="retainersByAgeData" :keyArray="['total', 'age05', 'age610', 'age1115', 'age1620', 'age2125', 'age250']" :labelKey="'location_name'" />
                <div>
                  <div class="small-loader" v-if="isLoadingPerAge">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 my-2" v-if="!hide_claim_outcome">
          <div class="main-content px-4 h-100">
            <div class="p-2 h-100">
              <div class="card card-primary bg-black mt-3 common-bg h-100">
                <h3 class="chart-card-title">Outcome / End Results of Claim (Death, Surgery, Hospital, etc.)</h3>
                <CfChart :data="ClaimOutcomeData?.cf_counts" v-if="!isLoadingClaimOutcomeData" />
                <div>
                  <div class="small-loader" v-if="isLoadingClaimOutcomeData">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 my-2" v-if="!hide_disease_type">
          <div class="main-content px-4 h-100">
            <div class="p-2 h-100">
              <div class="card card-primary bg-black mt-3 common-bg h-100">
                <h3 class="chart-card-title">Injury / Disease Type (Disease, Auto, etc.)</h3>
                <CfChart :data="DiseaseType?.cf_counts" v-if="!isLoadingDiseaseType" />
                <div>
                  <div class="small-loader" v-if="isLoadingDiseaseType">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 my-2" v-if="!hide_accident_type">
          <div class="main-content px-4 h-100">
            <div class="p-2 h-100">
              <div class="card card-primary bg-black mt-3 common-bg h-100">
                <h3 class="chart-card-title">Accident Type</h3>
                <CfChart :data="AccidentType?.cf_counts" v-if="!isLoadingAccidentType" />
                <div>
                  <div class="small-loader" v-if="isLoadingAccidentType">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 my-2" v-if="!hide_brand">
          <div class="main-content px-4 h-100">
            <div class="p-2 h-100">
              <div class="card card-primary bg-black mt-3 common-bg h-100">
                <h3 class="chart-card-title">Brand (car brand, pharma brand, generic)</h3>
                <CfChart :data="Brand?.cf_counts" v-if="!isLoadingBrand" />
                <div>
                  <div class="small-loader" v-if="isLoadingBrand">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 my-2" v-if="!hide_hospital_name">
          <div class="main-content px-4 h-100">
            <div class="p-2 h-100">
              <div class="card card-primary bg-black mt-3 common-bg h-100">
                <h3 class="chart-card-title">Hospital</h3>
                <CfChart :data="TreatmentHospitalName?.cf_counts" v-if="!isLoadingTreatmentHospitalName" />
                <div>
                  <div class="small-loader" v-if="isLoadingTreatmentHospitalName">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 my-2" v-if="!hide_dq_status">
          <div class="main-content px-4 h-100">
            <div class="p-2 h-100">
              <div class="card card-primary bg-black mt-3 common-bg h-100">
                <h3 class="chart-card-title">DQ Reasons</h3>
                <CfChart :data="DqStatus?.cf_counts" v-if="!isLoadingDqStatus" />
                <div>
                  <div class="small-loader" v-if="isLoadingDqStatus">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { auth, db } from "../firebaseConfig";
/* import { auth } from "../firebaseConfig"; */
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
// import Chart from "chart.js/auto";
import Chart from "@/components/Chart.vue";
import AreaChart from "@/components/AreaChart.vue";
import ColumChart from "@/components/ColumChart.vue";
import CfChart from "@/components/CfChart.vue";
import moment from 'moment'
import 'moment-timezone';
import { query, collection, getDocs, where, updateDoc } from '@firebase/firestore';

export default {
  name: "CampaignDashboardComponent",
  components: {
    Navbar,
    Sidebar,
    Chart,
    AreaChart,
    ColumChart,
    CfChart
  },
  data() {
    return {
      selectedLocationId: "",
      campaignDashboardCounts: {},
      locations: [],
      changeTypes: "NEC",
      start_date: '',
      end_date: '',
      dateFilter:'',
      showCustomDateSelect:false,
      retainersPerState: null,
      retainersByAge: null,
      retainersByAgeData: [],
      CfVals: [],
      ClaimOutcomeData: null,
      DiseaseType: null,
      AccidentType: null,
      Brand: null,
      TreatmentHospitalName: null,
      DqStatus: null,
      retainersByDate: null,
      loading: true,
      isLoadingPerState: true,
      isLoadingPerTotal: true,
      isLoadingPerAge: true,

      isLoadingClaimOutcomeData: true,
      isLoadingDiseaseType: true,
      isLoadingAccidentType: true,
      isLoadingBrand: true,
      isLoadingTreatmentHospitalName: true,
      isLoadingDqStatus: true,
    };
  },
  computed: {
    totalRecordsByDate: function() {
      return this.retainersByDate && this.retainersByDate.retainers_per_date ? Object.values(this.retainersByDate.retainers_per_date).reduce((partialSum, a) => partialSum + a, 0) : 0;
    }
  },
  setup() {
    let campaign_type = ref("Client");
    let visibleSidebar = ref(false);
    //const campain_displays = ref({});
    const store = useStore();
    const current_user_id = ref("");

    let hide_retainers_per_state = ref(false);
    let hide_retainers_record_count = ref(false);
    let hide_injured_age = ref(false);
    let hide_claim_outcome = ref(false);
    let hide_disease_type = ref(false);
    let hide_accident_type = ref(false);
    let hide_brand = ref(false);
    let hide_hospital_name = ref(false);
    let hide_dq_status = ref(false);

    //campain_displays.value["hide_retainers_per_state"] = false;

    auth.onAuthStateChanged( async (user) => {
      console.log("Auth State Changed!");
      store.dispatch("fetchUser", user);
      console.log("User Obj: ");
      console.log(user);
      //current_user_id.value = user.uid;
      set_stats_display(user.uid);
      
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const set_stats_display = async (c_user_id) => {
      console.log("CUser ID: " + c_user_id);
      const profile_q = query(
        collection(db, "profiles"),
        where("user_id", "==", c_user_id )
      );

      const profilesQuerySnap = await getDocs(profile_q);
      profilesQuerySnap.docs.forEach(p => {
        console.log("Profile Info: ");
        console.log(p);
        var profile_data = p.data();
        //console.log("Campaign Displays Pre: ");
        //console.log(campain_displays.value);
        hide_retainers_per_state.value = profile_data?.campain_displays?.hide_retainers_per_state;
        hide_retainers_record_count.value = profile_data?.campain_displays?.hide_retainers_record_count;
        hide_injured_age.value = profile_data?.campain_displays?.hide_injured_age;
        hide_claim_outcome.value = profile_data?.campain_displays?.hide_claim_outcome;
        hide_disease_type.value = profile_data?.campain_displays?.hide_disease_type;
        hide_accident_type.value = profile_data?.campain_displays?.hide_accident_type;
        hide_brand.value = profile_data?.campain_displays?.hide_brand;
        hide_hospital_name.value = profile_data?.campain_displays?.hide_hospital_name;
        hide_dq_status.value = profile_data?.campain_displays?.hide_dq_status;
        //console.log("Campaign Displays Post: ");
        //console.log(campain_displays.value);
      });
    }

    const drawBarChart = () => {
      var ctx = document.getElementById("bar-chart").getContext("2d");
      new Chart(ctx, {
        type: "bar",
        plugins: [
          {
            afterLayout: (chart) => {
              let ctx = chart.ctx;
              ctx.save();
              let yAxis = chart.scales["y"];
              let yBottom = yAxis.getPixelForValue(0);
              let yTop = yAxis.getPixelForValue(200);
              let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(1, "#D40DFF");
              chart.data.datasets[0].backgroundColor = gradient;
              chart.data.datasets[0].borderRadius = {
                topLeft: 20,
                topRight: 20,
              };
              ctx.restore();
            },
          },
        ],
        maintainAspectRatio: false,
        responsive: true,
        data: {
          labels: [
            "Ventura Viribuz",
            "Flynn Feus",
            "Flynn NEC",
            "Flynn Greathouse NEC",
            "Flynn TBSI NEC",
            "Flynn Rose NEC",
            "HMR",
            "More Commas",
            "Flynn Lowe NEC",
            "Sheehy",
            "Flynn KM Trial NEC",
            "Velaw City",
            "THT Marketing",
            "Miller-Ventura",
          ],
          datasets: [
            {
              label: "Sales",
              tension: 0.4,
              borderWidth: 0,
              borderSkipped: false,
              data: [
                410, 160, 60, 180, 460, 60, 340, 190, 460, 460, 60, 340, 190,
                460,
              ],
              maxBarThickness: 20,
            },
            {
              label: "Point",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#D4F100",
              data: [
                450, 200, 100, 220, 500, 100, 400, 230, 500, 500, 100, 400, 230,
                500,
              ],
              maxBarThickness: 6,
              type: "scatter",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 500,
                beginAtZero: true,
                padding: 15,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                maxRotation: 0,
                minRotation: 0,
                color: "#fff",
              },
            },
          },
        },
      });
    };
    const drawLineChart = () => {
      var ctx1 = document
        .getElementById("line-chart-gradient")
        .getContext("2d");
      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
      gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");
      new Chart(ctx1, {
        type: "bar",
        plugins: [
          {
            afterLayout: (chart) => {
              let ctx = chart.ctx;
              ctx.save();
              let yAxis = chart.scales["y"];
              let yBottom = yAxis.getPixelForValue(0);
              let yTop = yAxis.getPixelForValue(200);
              let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(1, "#D40DFF");
              chart.data.datasets[0].backgroundColor = gradient;
              chart.data.datasets[0].borderRadius = {
                topLeft: 20,
                topRight: 20,
              };
              ctx.restore();
            },
          },
        ],
        data: {
          labels: [
            "Ventura Viribuz",
            "Flynn Feus",
            "Flynn NEC",
            "Flynn Greathouse NEC",
            "Flynn TBSI NEC",
            "Flynn Rose NEC",
            "HMR",
            "More Commas",
            "Flynn Lowe NEC",
            "Sheehy",
            "Flynn KM Trial NEC",
            "Velaw City",
            "THT Marketing",
            "Miller-Ventura",
          ],
          datasets: [
            {
              label: "Sales",
              tension: 0.4,
              borderWidth: 0,
              borderSkipped: false,
              data: [
                410, 160, 60, 180, 460, 60, 340, 190, 460, 460, 60, 340, 190,
                460,
              ],
              maxBarThickness: 20,
            },
            {
              label: "Point",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#D4F100",
              data: [
                450, 200, 100, 220, 500, 100, 400, 230, 500, 500, 100, 400, 230,
                500,
              ],
              maxBarThickness: 6,
              type: "scatter",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 500,
                beginAtZero: true,
                padding: 15,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
              },
            },
          },
        },
      });
    };
    const drawMixChart = () => {
      var ctx = document.getElementById("bar-chart-gradient").getContext("2d");
      new Chart(ctx, {
        type: "bar",
        plugins: [
          {
            afterLayout: (chart) => {
              let ctx = chart.ctx;
              ctx.save();
              let yAxis = chart.scales["y"];
              let yBottom = yAxis.getPixelForValue(0);
              let yTop = yAxis.getPixelForValue(200);
              let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(1, "#D40DFF");
              chart.data.datasets[0].backgroundColor = gradient;
              chart.data.datasets[0].borderRadius = {
                topLeft: 20,
                topRight: 20,
              };
              ctx.restore();
            },
          },
        ],
        data: {
          labels: [
            "Ventura Viribuz",
            "Flynn Feus",
            "Flynn NEC",
            "Flynn Greathouse NEC",
            "Flynn TBSI NEC",
            "Flynn Rose NEC",
            "HMR",
            "More Commas",
            "Flynn Lowe NEC",
            "Sheehy",
            "Flynn KM Trial NEC",
            "Velaw City",
            "THT Marketing",
            "Miller-Ventura",
          ],
          datasets: [
            {
              label: "Sales",
              tension: 0.4,
              borderWidth: 0,
              borderSkipped: false,
              data: [
                410, 160, 60, 180, 460, 60, 340, 190, 460, 460, 60, 340, 190,
                460,
              ],
              maxBarThickness: 20,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 500,
                beginAtZero: true,
                padding: 15,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
              },
            },
          },
        },
      });
    };

    const changeType = (c_type) => {
      console.log(c_type, "c_type");
      this.getCampaignName(c_type);
      if (c_type != "Client") {
        drawBarChart();
        drawLineChart();
        drawMixChart();
      }
    };

    const toggleSidebar = () => {
      visibleSidebar.value = !visibleSidebar.value;
    };

    // Adding categories
    const handleManageStats = async ( ) => {
      
      /* console.log("Object: ");
      console.log(obj);
      console.log("Event: ");
      console.log(event); */
      /* console.log("Campaign Displays Inside Func: ");
      console.log(campain_displays.value); */
      const current_user = store.getters.user;
      const current_user_id = current_user.data.uid;
      /* console.log( "Current User: " );
      console.log( current_user );
      console.log( "User ID: " + current_user_id ); */

      const campain_displays = {};
      campain_displays.hide_retainers_per_state = hide_retainers_per_state?.value || false;
      campain_displays.hide_retainers_record_count = hide_retainers_record_count?.value || false;
      campain_displays.hide_injured_age = hide_injured_age?.value || false;
      campain_displays.hide_claim_outcome = hide_claim_outcome?.value || false;
      campain_displays.hide_disease_type = hide_disease_type?.value || false;
      campain_displays.hide_accident_type = hide_accident_type?.value || false;
      campain_displays.hide_brand = hide_brand?.value || false;
      campain_displays.hide_hospital_name = hide_hospital_name?.value || false;
      campain_displays.hide_dq_status = hide_dq_status?.value || false;

      const profile_q = query(
        collection(db, "profiles"),
        where("user_id", "==", current_user_id)
      );
      const profilesQuerySnap = await getDocs(profile_q);
      profilesQuerySnap.docs.forEach(p => {
        updateDoc(p.ref, {
          campain_displays: campain_displays
        })
      });

    };

    return { user, changeType, campaign_type, toggleSidebar, visibleSidebar, handleManageStats, current_user_id, hide_retainers_per_state, hide_retainers_record_count, hide_injured_age, hide_claim_outcome, hide_disease_type, hide_accident_type, hide_brand, hide_hospital_name, hide_dq_status };
  },
  methods: {
    changeLocation: function (loc_type) {
      this.selectedLocationId = loc_type;
      this.changeTypes = "";
      this.loadData();
    },
    changeCampaignType: function (campaign_type) {
      this.changeTypes = campaign_type;
      this.selectedLocationId = ""
      this.loadData();
    },
    getCampaignName: function (campaignName) {
      console.log(campaignName, "campaignName");
      return campaignName;
    },
    getLocations: async function () {
      const store = useStore();
      this.axios.get("/LocationsList").then(async (res) => {
        await store.dispatch("getContacts", { data: res });
      });
    },
    getCampaignDashboardCounts: async function () {
      this.loading = true;
      let url = "/CampaignDashboardCounts";
      if (this.selectedLocationId && this.selectedLocationId != "")
        url = url + `?location_id=${this.selectedLocationId}`;
      if (this.changeTypes)
        url = (url.length > 24 ? url + `&campaign=${this.changeTypes}` : url + `?campaign=${this.changeTypes}`);
      if (this.start_date != "" && this.end_date != "")
        url = url + `&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.axios
        .get(url)
        .then(async (res) => {
          this.loading = false;
          this.campaignDashboardCounts = res?.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    applyDateFilter: async function (event) {
      this.showCustomDateSelect = false;
      this.dateFilter = event;
      if (event == 'all') {
        this.start_date = '';
        this.end_date = '';
      } else if (event == '30') {
        this.start_date = moment(new Date()).subtract(30, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
      } else if (event == '60') {
        this.start_date = moment(new Date()).subtract(60, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
      } else if (event == '90') {
        this.start_date = moment(new Date()).subtract(90, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
      } else if (event == 'custom') {
        this.showCustomDateSelect = true;
        this.start_date = '';
        this.end_date = '';
      }
      this.loadData();
    },
    getRetainersPerState: async function() {
      this.isLoadingPerState = true;
      let params = '?';
      if (this.selectedLocationId) {
        params += `location_id=${this.selectedLocationId}&`;
      }
      if (this.changeTypes) {
        params += `campaign=${this.changeTypes}`;
      }
      if (this.start_date != "" && this.end_date != "")
        params = params + `&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.axios.get(`/RetainersPerState${params}`).then(async (res) => {
        this.retainersPerState = res?.data;
        console.log("retainersPerState", res?.data);
        this.isLoadingPerState = false;
      })
      .catch(() => {
        this.isLoadingPerState = false;
      });
    },
    getRetainersByAge: async function() {
      this.isLoadingPerAge = true;
      let params = '?';
      if (this.selectedLocationId) {
        params += `location_id=${this.selectedLocationId}&`;
      }
      if (this.changeTypes) {
        params += `campaign=${this.changeTypes}`;
      }
      if (this.start_date != "" && this.end_date != "")
        params = params + `&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.axios.get(`/RetainersByAgeBracket${params}`).then(async (res) => {
        this.retainersByAge = res?.data;
        const compare = (a, b) => {
          if (a.total < b.total) {
            return 1;
          }
          if (a.total > b.total) {
            return -1;
          }
          return 0;
        }
        this.retainersByAgeData = (res?.data?.retainers_by_age_bracket || []).sort(compare);
        this.isLoadingPerAge = false;
      })
      .catch(() => {
        this.isLoadingPerAge = false;
      });
    },
    getCfVals: async function(field_name) {
      //console.log("Field Name: " + field_name);
      this.isLoadingCfVals = true;
      this.isLoadingClaimOutcomeData = true;
      this.isLoadingDiseaseType = true;
      this.isLoadingAccidentType = true;
      this.isLoadingBrand = true;
      this.isLoadingTreatmentHospitalName = true;
      this.isLoadingDqStatus = true;
      let params = '?';
      if (this.selectedLocationId) {
        params += `location_id=${this.selectedLocationId}&`;
      }
      if (this.changeTypes) {
        params += `campaign=${this.changeTypes}`;
      }
      params += `&cf=${field_name}`;
      if (this.start_date != "" && this.end_date != "")
        params = params + `&start_date=${this.start_date}&end_date=${this.end_date}`;
        this.axios.get(`/CfCounts${params}`).then(async (res) => {
        if( field_name == 'Claim Outcome' ){
          this.ClaimOutcomeData = res?.data;  
          //console.log("ClaimOutcomeData", res?.data);
          this.isLoadingClaimOutcomeData = false;
        } else if( field_name == 'Injury/Disease Type' ){
          this.DiseaseType = res?.data;  
          //console.log("DiseaseType", res?.data);
          this.isLoadingDiseaseType = false;
        } else if( field_name == 'Accident Type' ){
          this.AccidentType = res?.data;  
          //console.log("AccidentType", res?.data);
          this.isLoadingAccidentType = false;
        } else if( field_name == 'Brand' ){
          this.Brand = res?.data;  
          //console.log("Brand", res?.data);
          this.isLoadingBrand = false;
        } else if( field_name == 'Hospital Name' ){
          this.TreatmentHospitalName = res?.data;  
          //console.log("TreatmentHospitalName", res?.data);
          this.isLoadingTreatmentHospitalName = false;
        } else if( field_name == 'DQ Status' ){
          this.DqStatus = res?.data;  
          //console.log("DqStatus", res?.data);
          this.isLoadingDqStatus = false;
        } else {
          this.CfVals = res?.data;
          this.isLoadingCfVals = false;
        }
      })
      .catch(() => {
        this.isLoadingCfVals = false;
        this.isLoadingClaimOutcomeData = false;
        this.isLoadingDiseaseType = false;
        this.isLoadingAccidentType = false;
        this.isLoadingBrand = false;
        this.isLoadingTreatmentHospitalName = false;
        this.isLoadingDqStatus = false;
      });
    },
    getRetainersByDate: async function() {
      this.isLoadingPerTotal = true;
      let params = '?';
      if (this.selectedLocationId) {
        params += `location_id=${this.selectedLocationId}&`;
      }
      if (this.changeTypes) {
        params += `campaign=${this.changeTypes}`;
      }
      if (this.start_date != "" && this.end_date != "")
        params = params + `&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.axios.get(`/RetainersPerDate${params}`).then(async (res) => {
        this.retainersByDate = res?.data;
        this.isLoadingPerTotal = false;
      })
      .catch(() => {
        this.isLoadingPerTotal = false;
      });
    },
    onChangeStartDate: function(evt) {
      this.start_date = moment(evt.target.value).format('YYYY-MM-DD');
    },
    onChangeEndDate: function(evt) {
      this.end_date = moment(evt.target.value).format('YYYY-MM-DD');
      this.loadData();
    },
    loadData: async function() {
      this.isLoading = true;
      await this.getCampaignDashboardCounts();
      console.log("Hide Retainers Per STate: " + this.hide_retainers_per_state);
      
      if( !this.hide_retainers_per_state )  await this.getRetainersPerState();
      if( !this.hide_injured_age )  await this.getRetainersByAge();
      if( !this.hide_claim_outcome )  await this.getCfVals('Claim Outcome');
      if( !this.hide_disease_type )  await this.getCfVals('Injury/Disease Type');
      if( !this.hide_accident_type )  await this.getCfVals('Accident Type');
      if( !this.hide_brand )  await this.getCfVals('Brand');
      if( !this.hide_hospital_name )  await this.getCfVals('Hospital Name');
      if( !this.hide_dq_status )  await this.getCfVals('DQ Status');
      if( !this.hide_retainers_record_count )  await this.getRetainersByDate();
      this.isLoading = false;      
    }
  },
  created: function () {
    this.moment = moment;
  },
};
</script>