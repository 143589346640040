<template>
  <div>
    <highcharts class="hc" :options="getChartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import borderRadius from "highcharts-border-radius";
import stockInit from 'highcharts/modules/stock'

exportingInit(Highcharts);
borderRadius(Highcharts);
stockInit(Highcharts);

export default {
  props: ["data", "keyArray", "labelKey"],
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          backgroundColor: null,
        },
        title: {
          text: "",
        },
        subtitle: {
          //   text:
          //     "Source: " +
          //     '<a href="https://www.ssb.no/en/statbank/table/08940/" ' +
          //     'target="_blank">SSB</a>',
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          floating: false,
          backgroundColor: null,
          itemStyle: {
            color: '#fff'
          },
          itemHoverStyle: {
            color: '#fff'
          }
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: this.getCategories(),
          crosshair: true,
          labels: {
            style: {
              color: '#fff',
              fontWeight: 'bold',
            }
          },
          min: 0,
          max: 4,
          scrollbar: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            useHTML: true,
            text: "",
            // text: "Million tonnes CO<sub>2</sub>-equivalents",
          },
          labels: {
            style: {
              color: '#fff',
              fontWeight: 'bold',
            }
          }
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            borderRadiusTopLeft: 10,
            borderRadiusTopRight: 10,
          }
        },
        series: [
          {
            name: "Total No. of Retainers",
            data: this.getData('total'),
            color: '#ffffff',
          },
          {
            name: "Age 0 - 5",
            data: this.getData('age05'),
            color: '#D40DFF',
          },
          {
            name: "Age 6 - 10",
            data: this.getData('age610'),
            color: '#7E51FF',
          },
          {
            name: "Age 11-15",
            data: this.getData('age1115'),
            color: '#D4F100',
          },
          {
            name: "Age 16-20",
            data: this.getData('age1620'),
            color: '#00C6F1',
          },
          {
            name: "Age 21-25",
            data: this.getData('age2125'),
            color: '#F18200',
          },
          {
            name: "Age Over 25",
            data: this.getData('age250'),
            color: '#F1BC00',
          },
        ],
        exporting: { enabled: false }
      },
    };
  },
  computed: {
    getChartOptions() {
      return {
        chart: {
          type: "column",
          backgroundColor: null,
        },
        title: {
          text: "",
        },
        subtitle: {
          //   text:
          //     "Source: " +
          //     '<a href="https://www.ssb.no/en/statbank/table/08940/" ' +
          //     'target="_blank">SSB</a>',
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          floating: false,
          backgroundColor: null,
          itemStyle: {
            color: '#fff'
          },
          itemHoverStyle: {
            color: '#fff'
          }
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: this.getCategories(),
          crosshair: true,
          labels: {
            style: {
              color: '#fff',
              fontWeight: 'bold',
            }
          },
          min: 0,
          max: 4,
          scrollbar: {
            enabled: true,
            barBackgroundColor: 'gray',
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: 'gray',
            buttonBorderWidth: 0,
            buttonBorderRadius: 7,
            trackBackgroundColor: 'none',
            trackBorderWidth: 0,
            trackBorderRadius: 8,
            trackBorderColor: '#CCC',
            height: 10,
          },
          tickPositions: this.getTickPosition(),
        },
        yAxis: {
          title: {
            useHTML: true,
            text: "",
            // text: "Million tonnes CO<sub>2</sub>-equivalents",
          },
          labels: {
            style: {
              color: '#fff',
              fontWeight: 'bold',
            }
          }
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            borderRadiusTopLeft: 10,
            borderRadiusTopRight: 10,
          }
        },
        series: [
          {
            name: "Total No. of Retainers",
            data: this.getData('total'),
            color: '#ffffff',
          },
          {
            name: "Age 0 - 5",
            data: this.getData('age05'),
            color: '#D40DFF',
          },
          {
            name: "Age 6 - 10",
            data: this.getData('age610'),
            color: '#7E51FF',
          },
          {
            name: "Age 11-15",
            data: this.getData('age1115'),
            color: '#D4F100',
          },
          {
            name: "Age 16-20",
            data: this.getData('age1620'),
            color: '#00C6F1',
          },
          {
            name: "Age 21-25",
            data: this.getData('age2125'),
            color: '#F18200',
          },
          {
            name: "Age Over 25",
            data: this.getData('age250'),
            color: '#F1BC00',
          },
        ],
        exporting: { enabled: false }
      }
    },
  },
  methods: {
    getCategories: function () {
      return this.data ? this.data.map(item => item['location_name']) : [];
    },
    getData: function (key) {
      return this.data ? this.data.map(item => item[key]) : [];
    },
    getTickPosition: function () {
      return this.data ? this.data.map((item, index) => index) : [];
    }
  }
};
</script>
