<template>
  <div id="dashboard">
    <div class="container-fluid top-0 position-sticky z-index-sticky header">
      <div class="row">
        <div class="col-12">
          <navbar isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" v-bind:darkMode="true"
            isBtn="bg-gradient-success" :user="user" :changeType="changeType" :showSidebar="toggleSidebar"
            :headersDropdown="false" />
        </div>
      </div>
    </div>
    <div class="sidebar-container" :class="visibleSidebar ? 'visible-sidebar' : 'hide-sidebar'">
      <div class="sidebar-mask" @click="toggleSidebar()"></div>
      <sidebar :user="user" />
    </div>
    <div class="container">
      <div class="main-content px-4 mt-4">
        <h4 class="text-white-h">Current Case Fulfillments</h4>
        <div class="p-2">
          <div class="card card-primary mt-3  custom-card">
            <div class="case-bar-chart">
              <div class="updateDate">Updated: <span>{{ moment.tz(new Date() ,
              'America/Los_Angeles').format('LL')}}</span></div>
              <div class="chartAreaWrapper">
                <div class="chartAreaWrapper2" style="overflow-x: auto;">
                  <div style="height:300px; width:100%;" class="text-white d-flex justify-content-center align-items-center" v-if="isloadingTargets">Loading...</div>
                  <canvas id="bar-chart" class="chart-canvas" width="4000" height="300"></canvas>
                </div>
              </div>
              <canvas id="axis-full" height="300" width="0" style="display: none;"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content px-4 mt-4">
        <h4 class="text-white-h">Fulfillment Targets</h4>
        <div class="p-2">
          <div class="card card-primary  mt-3  custom-card">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-white text-center" v-on:click="sort('law_firm')">Law Firm</th>
                  <th class="text-white text-center" v-on:click="sort('cases_paid_for_number')"> No. of Cases Bought
                  </th>
                  <th class="text-white text-center" v-on:click="sort('case_price')"> Case Price </th>
                  <th class="text-white text-center" v-on:click="sort('amount_paid')"> Amount Paid </th>
                  <th class="text-white text-center" v-on:click="sort('cases_left_number')"> No. of Cases Left </th>
                  <th class="text-white text-center" v-on:click="sort('cases_acquired_number')"> Cases Acquired </th>
                  <!-- <th class="text-white text-center" v-on:click="sort('case_type')"> Case Type </th> -->
                  <th class="text-white text-center" v-on:click="sort('order_date')"> Order Date </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="isloadingTargets">
                  <td colspan="8" class="text-white text-center">Loading...</td>
                </tr>
                <template v-if="!isloadingTargets">
                  <tr v-for="target in fulfillmentDashboardTargets?.fulfillment_dashboard.slice(startIdx, endIdx)" :key="target.id">
                    <td class="text-white text-center">{{target.law_firm}}</td>
                    <td class="text-white text-center">{{target.cases_paid_for_number}}</td>
                    <td class="text-white text-center">{{target.case_price}}</td>
                    <td class="text-white text-center">{{target.amount_paid}}</td>
                    <td class="text-white text-center">{{target.cases_left_number}}</td>
                    <td class="text-white text-center">{{target.cases_acquired_number}}</td>
                    <!-- <td class="text-white text-center">{{target.case_type}}</td> -->
                    <td class="text-white text-center">{{target.order_date}}</td>
                  </tr>
                </template>

              </tbody>
            </table>
            <!-- <vue-awesome-paginate v-if="!isloadingTargets"
              :total-items="fulfillmentDashboardTargets?.total_fulfillment_count" :items-per-page="per_page"
              :max-pages-shown="5" :current-page="page" :on-click="paginate" /> -->
              <div class="card-footer ms-3" >
                <div class="d-flex">
                  <div class="prevlink mx-2">
                    <a href="javascript:void(0);" class="text-white fs-5 px-3 py-2" :class="pageNumber === 1 ? 'btn disabled' : 'btn'" @click="prevPage">
                      &laquo;
                      </a>
                  </div>
                  <div class="nextlink">
                    <a href="javascript:void(0);" class="text-white fs-5 px-3 py-2"
                      :class="pageNumber === Math.ceil(fulfillmentDashboardTargets?.fulfillment_dashboard.length  / pageLimit) || isNaN(Math.ceil(fulfillmentDashboardTargets?.fulfillment_dashboard.length / pageLimit)) ? 'btn disabled' : 'btn'"
                      @click="nextPage">
                      &raquo;
                    </a>
                  </div>
                </div>
                <div v-if="fulfillmentDashboardTargets?.fulfillment_dashboard.length > 0" style="margin-left: 10px" class="text-sm text-white">Page {{ pageNumber }} of {{
                    fulfillmentDashboardTargets?.fulfillment_dashboard.length ?
                      (Math.ceil(fulfillmentDashboardTargets?.fulfillment_dashboard.length / pageLimit)) : 1
                }}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { auth } from "../firebaseConfig";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Chart from "chart.js/auto";
import moment from 'moment'
import 'moment-timezone';

export default {
  name: "FulfilDashboardComponent",
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      selectedLocationId: "",
      contacts: [],
      locations: [],
      fulfillmentDashboardTargets: {
        fulfillment_dashboard:[]
      },
      isloadingTargets: false,
      per_page: 20,
      page: 1,
      order_by: "law_firm",
      order: "ASC",
      currentCaseXAxis:[],
      suggestedMax:0,
      totalCasesOrdered:[],
      casesAccquired:[],
      pageNumber: 1,
      pageLimit: 30,
      startIdx: 0,
      endIdx: 30,
    };
  },
  setup() {
    //let currentCaseXAxis = ref(null);
    let visibleSidebar = ref(false);
    const store = useStore();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const toggleSidebar = () => {
      visibleSidebar.value = !visibleSidebar.value;
    };   

    return { user, toggleSidebar, visibleSidebar };
  },
  methods: {
    getFulfillmentDashboard: async function () {
      this.isloadingTargets = true;
      this.axios.get(`/FulfillmentDashboard?per_page=${this.per_page}&page=${this.page}&order_by=${this.order_by}&order=${this.order}`).then(async (res) => {
        this.fulfillmentDashboardTargets = res?.data;
        this.isloadingTargets = false;
        console.log(res?.data?.fulfillment_dashboard)
        const compare = (a, b) => {
          if (a.cases_paid_for_number < b.cases_paid_for_number) {
            return 1;
          }
          if (a.cases_paid_for_number > b.cases_paid_for_number) {
            return -1;
          }
          return 0;
        }
        res?.data?.fulfillment_dashboard?.sort(compare).forEach((el) => {
            this.currentCaseXAxis.push(this.wrapLabels(el.location_name, 6))
            this.totalCasesOrdered.push(el.cases_paid_for_number);
            this.casesAccquired.push(el.cases_acquired_number)
        })
        this.drawBarChart()
      });
    },
    sort: async function (event) {
      if (this.order_by == event) {
        if (this.order == "ASC") {
          this.order = "DESC";
        } else {
          this.order = "ASC";
        }
      } else {
        this.order = "ASC";
      }
      this.order_by = event;
      this.page = 1;
      this.paginate(1);
    },
     wrapLabels (str, limit) {
        const words = str.split(" ");
        let aux = []
        let concat = []

        for (let i = 0; i < words.length; i++) {
          concat.push(words[i])
          let join = concat.join(' ')
          if (join.length > limit) {
            aux.push(join)
            concat = []
          }
        }

        if (concat.length) {
          aux.push(concat.join(' ').trim())
        }

        return aux
    },
    paginate: async function (event) {
      this.page = event;
      await this.getFulfillmentDashboard();
    },
    nextPage() {
      this.pageNumber += 1;
      this.startIdx += this.pageLimit;
      this.endIdx += this.pageLimit;
    },
    prevPage() {
      this.pageNumber -= 1;
      this.startIdx -= this.pageLimit;
      this.endIdx -= this.pageLimit;
    },
    drawBarChart: async function () {
      var ctx = document.getElementById("bar-chart").getContext("2d");
      var chart_context = new Chart(ctx, {
        type: "bar",
        plugins: [
          {
            afterLayout: (chart) => {
              let ctx = chart.ctx;
              ctx.save();
              let yAxis = chart.scales["y"];
              let yBottom = yAxis.getPixelForValue(0);
              let yTop = yAxis.getPixelForValue(200);
              let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(0, "#7E51FF");
              gradient.addColorStop(1, "#D40DFF");
              chart.data.datasets[1].backgroundColor = gradient;
              chart.data.datasets[1].borderRadius = {
                topLeft: 20,
                topRight: 20,
              };
              ctx.restore();
            },
          },
        ],
        maintainAspectRatio: false,
        responsive: false,
        data: {
          labels: this.currentCaseXAxis,
          datasets: [
            {
              label: "Total Cases Ordered",
              tension: 0.4,
              textDirection: 'right',
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#D4F100",
              data:  this.totalCasesOrdered,
              maxBarThickness: 6,
              type: "scatter",
            },
            {
              label: "Case Acquired",
              tension: 0.4,
              textDirection: 'right',
              borderWidth: 0,
              borderSkipped: false,
              data: this.casesAccquired,
              barPercentage:0.8,
              maxBarThickness: 20,
              fontColor: '#ffffff'
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              align: 'center',
              position:'top',
              labels: {
                boxWidth: 15,
                boxHeight: 15,
                color: '#ffffff',
                padding: 10,
                font: 15,

              }
            },
          },
          interaction: {
            intersect: true,
            mode: "index",
            axis: 'y',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                lineWidth: 2,
                drawOnChartArea: true,
                drawTicks: false,
                color: 'rgba(217, 217, 217, 0.2)'
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                padding: 15,
                font: {
                  size: 13,
                  lineHeight: '30px',
                  weight: 700
                },
                color: "#fff",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                autoSkip: false,
                font: {
                  size: 10,
                  lineHeight: '17px',
                  weight: 700,
                  wordBreak: 'break-word'
                },
                maxRotation: 0,
                minRotation: 0,
                color: "#fff",
              },
            },
          },
          animation: {
            onComplete: function () {
              var sourceCanvas = chart_context.canvas;
              var copyWidth = chart_context.scales['y'].width - 10;
              var copyHeight = chart_context.scales['y'].height + chart_context.scales['y'].top + 10;
              var targetCtx = document.getElementById("axis-full").getContext("2d");
              targetCtx.canvas.width = copyWidth;
              targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
            }
          }
        },
      });
    }
  },
  mounted() {
    this.getFulfillmentDashboard()
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
