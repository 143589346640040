<template>
  <div>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
};
</script>