<template>
    <div id="user_management">
        <div class="container-fluid top-0 position-sticky z-index-sticky header">
            <div class="row">
                <div class="col-12">
                    <navbar isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" v-bind:darkMode="true"
                        isBtn="bg-gradient-success" :user="user" :changeType="changeType" :changeLocation="changeLocation"
                        :showSidebar="toggleSidebar" />
                </div>
            </div>
        </div>
        <div class="sidebar-container" :class="visibleSidebar ? 'visible-sidebar' : 'hide-sidebar'">
            <div class="sidebar-mask" @click="toggleSidebar()"></div>
            <sidebar :user="user" />
        </div>
        <div class="container">
            <div class="main-content px-4 mt-4">
                <div class="d-flex justify-content-end align-items-center px-2">
                    <argon-button variant="gradient" color="success" @click="moveToAddUser">
                        <i class="fa fa-plus"></i>
                        Add User
                    </argon-button>
                </div>
                <main class="mt-3 d-flex flex-wrap align-items-stretch">
                    <div class="card card-primary user-management-card p-2 w-100">
                        <table class="table" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Avatar</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!isLoading">
                                <tr v-for="(profile, index) in profiles" :key="profile.user_id">
                                    <td>{{ (index + 1) }}</td>
                                    <td>{{ profile.full_name }}</td>
                                    <td>{{ profile.email }}</td>
                                    <td>{{ profile.role_name }}</td>
                                    <td>
                                        <img v-if="profile.avatar" :src="profile.avatar"
                                            style="width: 25px; height: 25px; border-radius: 50px;" />
                                        <img v-if="!profile.avatar" src="../assets/img/non-user.webp"
                                            style="width: 25px; height: 25px; border-radius: 50px;" />
                                    </td>
                                    <td>
                                        <argon-button @click="moveToEdit(profile.user_id)"
                                            style="background-color: #D40DFF" title="Edit User">
                                            <i class="fa fa-edit"></i>
                                        </argon-button>
                                        <argon-button @click="deleteUser(profile.user_id)"
                                            style="background-color: #8050FF;margin-left: 10px;" :disabled="deleting" title="Delete User">
                                            <i class="fa fa-trash"></i>
                                        </argon-button>
                                        <argon-button @click="showResetModals(profile.user_id)"
                                            color="danger" style="margin-left: 10px;" :disabled="deleting" title="Reset Password">
                                            <i class="fa fa-lock"></i>
                                        </argon-button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="isLoading">
                                <tr>
                                    <td colspan="6" class="text-center font-weight-bold">
                                        Loading...
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </main>
            </div>
        </div>
        <Teleport to="body">
            <!-- use the modal component, pass in the prop -->
            <modal 
                :show="showModal"
                @close="showModal = false"
                title="Are you sure?"
                message="Do you really want to delete this user?"
            >
                <template #header>
                    <h4>Are you sure?</h4>
                </template>
                <template #body>
                    <p>Do you really want to delete this user?</p>
                </template>
                <template #footer>
                    <argon-button @click="showModal=false">Cancel</argon-button>
                    <argon-button @click="confirmDelete()" color="danger">Delete</argon-button>
                </template>
            </modal>
        </Teleport>
        <Teleport to="body">
            <!-- use the modal component, pass in the prop -->
            <modal :show="showResetModal" @close="showResetModal = false">
                <template #header>
                    <h4>Reset Password</h4>
                </template>
                <template #body>
                    <p class="danger" v-if="password_error_msg">{{ password_error_msg }}</p>
                    <label>Password</label>
                    <argon-input placeholder="Password" id="reset_password" type="password"></argon-input>
                    <label>Confirm Password</label>
                    <argon-input placeholder="Confirm Password" id="reset_confirm_password" type="password"></argon-input>
                </template>
                <template #footer>
                    <argon-button @click="showResetModal=false" color="danger">Cancel</argon-button>
                    <argon-button @click="resetPassword()">Reset</argon-button>
                </template>
            </modal>
        </Teleport>
    </div>
</template>
  
<script>
import { ref } from 'vue'
import { useStore } from "vuex";
import { computed } from "vue";
import { auth, db, functions } from '../firebaseConfig'
import { httpsCallable } from "@firebase/functions";
import Navbar from "@/components/Navbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Sidebar from '@/components/Sidebar.vue';
import Modal from '@/components/Modal.vue';
import { useRouter } from 'vue-router';
import { collection, getDocs, query, where, getDoc, doc, deleteDoc } from '@firebase/firestore';

const body = document.getElementsByTagName("body")[0];
export default {
    name: "UserManageComponent",
    components: {
        Navbar,
        ArgonButton,
        ArgonInput,
        Sidebar,
        Modal,
    },
    data() {
        return {
            profiles: [],
            deleting: false,
            showModal: false,
            selectedUser: null,
            showResetModal: false,
            password_error_msg: '',
            isLoading: false,
        }
    },
    setup() {
        let campaign_type = ref('Client')
        let visibleSidebar = ref(false)

        const store = useStore()
        const router = useRouter();

        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });

        const user = computed(() => {
            return store.getters.user;
        });

        const toggleSidebar = () => {
            visibleSidebar.value = !visibleSidebar.value;
        }

        const moveToAddUser = () => {
            router.push({ name: 'AddUser' });
        }

        const moveToEdit = (id) => {
            router.push({ name: 'EditUser', params: { userId: id } });
        }

        const deleteProfile = (id) => {
            console.log(id);
        }

        return { user, campaign_type, toggleSidebar, visibleSidebar, moveToAddUser, moveToEdit, deleteProfile }
    },
    methods: {
        getAllUsers: async function () {
            this.isLoading = true;
            const q = query(collection(db, 'profiles'));
            const querySnap = await getDocs(q);
            let users = [];
            for (let i = 0; i < querySnap.size; i++) {
                const profile = querySnap.docs[i].data();
                const role_q = query(collection(db, 'user_roles'), where('user_id', '==', profile.user_id));
                const roleQuerySnap = await getDocs(role_q);

                if (roleQuerySnap.docs[0]) {
                    const user_role = roleQuerySnap.docs[0].data();
                    console.log(user_role);
                    const rolesQuerySnap = await getDoc(doc(db, 'roles', user_role.role_id))
                    const role = rolesQuerySnap.data();
                    users.push({
                        ...profile,
                        role_name: role.name,
                    });
                } else {
                    users.push({
                        ...profile,
                        role_name: ''
                    });
                }
            }
            this.profiles = users;
            this.isLoading = false;
            return users;
        },
        deleteUser: async function (user_id) {
            this.showModal = true;
            this.selectedUser = user_id;
        },
        confirmDelete: async function () {
            const removeUser = httpsCallable(functions, 'deleteUser');
            this.deleting = true;
            const user_id = this.selectedUser;
            removeUser({
                uid: user_id,
            })
                .then(async (res) => {
                    this.selectedUser = null;
                    if (res) {
                        const q = query(collection(db, 'user_roles'), where('user_id', '==', user_id));
                        const querySnap = await getDocs(q);
                        querySnap.docs.forEach(snap => {
                            deleteDoc(snap.ref);
                        });

                        const locations_q = query(collection(db, 'user_locations'), where('user_id', '==', user_id));
                        const locationsQuerySnap = await getDocs(locations_q);
                        locationsQuerySnap.docs.forEach(snap => {
                            deleteDoc(snap.ref);
                        });

                        const profiles_q = query(collection(db, 'profiles'), where('user_id', '==', user_id));
                        const profilesQuerySnap = await getDocs(profiles_q);
                        profilesQuerySnap.docs.forEach(snap => {
                            deleteDoc(snap.ref);
                        });

                        this.deleting = false;
                        this.showModal = false;
                        this.getAllUsers();
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.selectedUser = null;
                    this.deleting = false;
                });
        },
        showResetModals: function(user_id) {
            this.showResetModal = true;
            this.selectedUser = user_id;
        },
        resetPassword: function() {
            if (!body.querySelector('#reset_password').value) {
                this.password_error_msg = 'Password can\'t be blank!';
                return;
            }
            if (body.querySelector('#reset_password').value.length < 6) {
                this.password_error_msg = 'You have to enter at least 6 digit!';
                return;
            }
            if (body.querySelector('#reset_password').value != body.querySelector('#reset_confirm_password').value) {
                this.password_error_msg = 'Confirm password doesn\'t match New password';
                return;
            }
            const editUser = httpsCallable(functions, 'updateUser');
            const userId = this.selectedUser;
            editUser({
                password: body.querySelector('#reset_password').value,
                uid: userId,
            })
            .then(res => {
                if(res) {
                    this.showResetModal = false;
                }
            })
        },
        changeLocation: async function() {

        },
    },
    mounted() {
        this.getAllUsers();
    },
};
</script>