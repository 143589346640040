<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl sidebarnav" :class="
    this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''
  " v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
        <div class="d-flex align-items-center">
          <img src="./../assets/img/burger_icon.svg" @click="toggleSidebarVisible()" />
          <h4 class="ms-md-5 text-white mb-0">Dashboard</h4>
        </div>
        <div class="pe-md-3 d-flex align-items-center" :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <div class="dropdown" v-if="headersDropdown">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
              aria-expanded="false" style="margin-bottom: 0px; color: white; border: 2px solid white">
              {{selectedLawFirm ? selectedLocationName : 'Law Firm'}}
            </button>
            <ul class="dropdown-menu law-firm" aria-labelledby="dropdownMenuButton" style="">
              <li v-for="list in locationList" :key="list.id" @click="changeLawFirm(list.hl_location_id, list.hl_location_name)">
                <a v-on:click="selectedLawFirm = list.hl_location_id" class="dropdown-item text-break" href="#">{{
                list.hl_location_name
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="campaign-type-dropdown dropdown ms-md-3" v-if="showAgentDropdown">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
            aria-expanded="false" style="margin-bottom: 0px; color: white; border: 2px solid white;width: 200px;max-width: 300px;min-width: 0px;">
            {{selectedAgentName ? selectedAgentName : "Agent Name" }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="max-height: 300px; overflow-y: auto;">
            <li v-for="agent in agentList" :key="agent.id" @click="changeAgentType(agent.hl_user_id, agent.hl_user_name)">
              <a v-on:click="selectedAgent = agent.id" class="dropdown-item text-break" href="#">{{
              agent.hl_user_name
              }}</a>
            </li>
            <li v-if="!selectedAgent">
              <p class="text-break mb-0 text-center font-weight-bold" style="font-size: 12px;">Select an Agent</p>
            </li>
            <li v-if="!selectedAgent && !agentList.length">
              <p class="text-break mb-0 text-center font-weight-bold" style="font-size: 12px;">No Agents</p>
            </li>
          </ul>
        </div>
        <div class="campaign-type-dropdown dropdown ms-md-3" v-if="showCampaignType">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
            aria-expanded="false" style="margin-bottom: 0px; color: white; border: 2px solid white;width: 200px;max-width: 300px;min-width: 0px;">
            {{selectedCampaignType ? selectedCampaignType : "Campaign Type" }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li v-for="campaign in campaignTypeList" :key="campaign.id" @click="changeCampaignType(campaign.name)">
              <a class="dropdown-item" href="#" @click="changeCampaignType(campaign.name)">{{ campaign.name }}</a>
            </li>
            <!-- <li>
              <a class="dropdown-item" href="#" @click="changeCampaignType('NEC')">NEC</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="changeCampaignType('MVA')">MVA</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="changeCampaignType('Camp Lejeune')">Camp Lejeune</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="changeCampaignType('APAP')">APAP</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="changeCampaignType('Zantac')">Zantac</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="changeCampaignType('Topamax')">Topamax</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { db } from "@/firebaseConfig";
import { collection, getDocs, query } from "@firebase/firestore";
import { mapMutations, mapActions } from "vuex";
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      locationsList: [],
      CampaignsList: [],
      selectedLawFirm: "",
      selectedLawFirmName: "",
      selectedAgent: "",
      selectedAgentName: "",
      userAllLocations: [],
      selectedCampaignType: "NEC",
      agentList: [],
    };
  },
  props: [
    "minNav",
    "textWhite",
    "changeType",
    "changeLocation",
    "changeAgent",
    "showSidebar",
    "user",
    "headersDropdown",
    "showCampaignType",
    "changeTypeValue",
    "showAgentDropdown",
  ],
  created() {
    this.minNav;
    this.changeTypeValue;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    changeLawFirm(t, locationName) {
      this.selectedCampaignType = '';
      this.selectedLawFirm = t;
      this.selectedLawFirmName = locationName;
      console.log(t);
      this.changeLocation(t);
    },
    changeCampaignType(t) {
      this.selectedLawFirm = '';
      console.log('this.changeTypeValue', this.changeTypeValue)
      this.selectedCampaignType = t;
      this.changeType(t);
    },
    changeAgentType(t, agentName) {
      this.selectedAgent = t;
      this.selectedAgentName = agentName;
      this.changeAgent(t);
    },
    toggleSidebarVisible() {
      this.showSidebar();
    },
    getLocationsList: async function () {
      this.axios.get("/LocationsList").then(async (res) => {
        this.locationsList = res?.data?.locations ?? [];
      });
    },
    getCampaignsList: async function () {
      this.axios.get("/CampaignsList").then(async (res) => {
        this.CampaignsList = res?.data?.campaigns ?? [];
      });
    },
    getAllUserLocations: async function () {
      const location_q = query(collection(db, "user_locations"));
      const locationQuerySnap = await getDocs(location_q);

      let result = [];
      if (locationQuerySnap.docs && locationQuerySnap.docs.length > 0) {
        locationQuerySnap.docs.forEach((loc) => {
          const data = loc.data();
          if (result.indexOf(data.locationId) < 0) {
            result.push(data.location_id);
          }
        });
        const firstLoc = locationQuerySnap.docs[0].data();
        if (this.changeLocation) {
          this.changeLawFirm(firstLoc?.location_id, '');
        }
      }
      this.userAllLocations = result;
      return result;
    },
    getAgentList: async function () {
      if (this.changeAgent) {
        this.axios.get("/AgentsList").then(async (res) => {
          //console.log(res, "RES");
          this.agentList = res?.data?.agents ?? [];
          this.selectedAgent = this.agentList[0]?.hl_user_id || '';
          this.selectedAgentName = this.agentList[0]?.hl_user_name || '';
          this.changeAgent(this.selectedAgent);
        });
      }
    },
  },
  computed: {
    locationList() {
      let totalLocations = [];
      const userInfo = this.userInfo;
      if (
        userInfo &&
        userInfo.role &&
        (userInfo.role.name == "Admin" || userInfo.role.name == "Super Admin")
      ) {
        let locations = this.locationsList ?? [];
        let result = this.userAllLocations ?? [];
        totalLocations = locations.filter(
          (loc) => result.indexOf(loc.hl_location_id) > -1
        );
      } else {
        if (userInfo) {
          const userLocations = userInfo?.locationId;
          const locations = this.locationsList ?? [];
          const filtered_locations = locations.filter(
            (loc) => userLocations.indexOf(loc.hl_location_id) > -1
          );
          totalLocations = filtered_locations;
        }
      }
      return totalLocations;
    },
    selectedLocationName() {
      let locations = this.locationsList ?? [];
      const selectedLocationId = this.selectedLawFirm;
      const selectedLoc = locations.find(loc => loc.hl_location_id === selectedLocationId);
      return selectedLoc?.hl_location_name || '';
    },
    currentRouteName() {
      return this.$route.name;
    },
    userInfo() {
      return this.user.data;
    },
    campaignTypeList() {
      let totalCampaigns = [];
      let locations = this.locationsList ?? [];
      const userInfo = this.userInfo;
      if (
        userInfo &&
        userInfo.role &&
        (userInfo.role.name == "Admin" || userInfo.role.name == "Super Admin")
      ) {
        totalCampaigns = this.CampaignsList ?? [];
      } else {
        if (userInfo) {
          const userLocations = userInfo?.locationId;
          const campaigns = this.CampaignsList ?? [];
          const filtered_campaigns = campaigns.filter(
            (campaign) => {
              for( var i=0; i < userLocations.length; i++ ){
                var selected_location_id = userLocations[i];
                var selected_location = locations.find(loc => loc.hl_location_id === selected_location_id);
                var selected_location_name = selected_location?.hl_location_name || '';
                if( selected_location_name.includes(campaign.name) ){
                  return true;
                }
              }
              return false;
            }
          );

          totalCampaigns = filtered_campaigns;
        }
      }
      return totalCampaigns;
    },
  },
  mounted() {
    this.getLocationsList();
    this.getCampaignsList();
    this.getAllUserLocations();
    this.getAgentList();
  },
};
</script>
