import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import store from "./store";
import router from "./routes";
import "./assets/app.scss";
import "./assets/custom_global.css";
import "./assets/new_Global.css";
import ArgonDashboard from "./argon-dashboard";
import { auth } from "./firebaseConfig";
import VueAwesomePaginate from "vue-awesome-paginate";
import moment from 'moment'


// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

// Set base URL
axios.defaults.baseURL = process.env.VUE_APP_API_URL || "";
axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_API_KEY
  ? "Bearer " + process.env.VUE_APP_API_KEY
  : "";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

let appInstance;
import HighchartsVue from "highcharts-vue";

auth.onAuthStateChanged(() => {
  if (!appInstance) {
    appInstance = createApp(App);
    appInstance.use(store);
    appInstance.use(router);
    appInstance.use(VueAwesomePaginate);
    appInstance.use(VueAxios, axios);
    appInstance.use(ArgonDashboard);
    appInstance.use(moment)
    appInstance.mount("#app");
    appInstance.use(HighchartsVue);
  }
});
