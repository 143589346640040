<template>
    <div id="user_management">
        <div class="container-fluid top-0 position-sticky z-index-sticky header">
            <div class="row">
                <div class="col-12">
                    <navbar isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" v-bind:darkMode="true"
                        isBtn="bg-gradient-success" :user="user" :changeType="changeType"
                        :showSidebar="toggleSidebar" />
                </div>
            </div>
        </div>
        <div class="sidebar-container" :class="visibleSidebar ? 'visible-sidebar' : 'hide-sidebar'">
            <div class="sidebar-mask" @click="toggleSidebar()"></div>
            <sidebar :user="user" />
        </div>
        <div class="container">
            <div class="main-content px-4 mt-4">
                <div class="d-flex justify-content-between align-items-center px-2">
                    <argon-button variant="gradient" color="danger" @click="moveToBack">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </argon-button>
                    <argon-button variant="gradient" color="success" @click="createUser" :disabled="saving">
                        <i class="fa fa-plus"></i>
                        Save
                    </argon-button>
                </div>
                <main class="mt-3 d-flex flex-wrap align-items-stretch">
                    <div class="card card-primary user-management-card p-4 w-100">
                        <div class="d-flex justify-content-center align-items-center position-relative overflow-hidden">
                            <img v-if="profile && profile.avatar" :src="profile.avatar" class="dashboard-avatar" />
                            <img v-if="!profile || profile && !profile.avatar" src="../assets/img/non-user.webp"
                                class="dashboard-avatar" />
                            <div class="position-absolute top-0 left-0 avatar-mask" :class="isOver ? 'show-mask' : ''"
                                @mouseenter="() => isOver = true" @mouseleave="() => isOver = false">
                                <i class="fa fa-edit"></i>
                                <input type="file" id="avatar_file" @change="imageChange" />
                            </div>
                        </div>
                        <label>Name</label>
                        <argon-input placeholder="Name" id="name" type="text" :value="profile ? profile.full_name : ''">
                        </argon-input>
                        <p v-if="errors.name" class="text-danger" style="font-size: 12px">{{ errors.name }}</p>
                        <label>Email</label>
                        <argon-input placeholder="Email" id="user_email" type="email" :value="profile ? profile.email : ''">
                        </argon-input>
                        <p v-if="errors.email" class="text-danger" style="font-size: 12px">{{ errors.email }}</p>
                        <div v-if="!userId">
                            <label>Password</label>
                            <argon-input placeholder="Password" id="user_password" type="password"></argon-input>
                            <p v-if="errors.password" class="text-danger" style="font-size: 12px">{{ errors.password }}
                            </p>
                        </div>
                        <label>Role</label>
                        <select class="form-control" id="role" @change="changeRole()">
                            <option v-for="role in roles" :value="role.id" :key="role.id"
                                :selected="profile && profile.role_id == role.id ? 'selected' : ''">{{ role.name }}
                            </option>
                        </select>
                        <p v-if="errors.role" class="text-danger" style="font-size: 12px">{{ errors.role }}</p>
                        <label class="mt-3">Locations</label>
                        <VueMultiselect v-model="selectedLocation" :options="locations" label="hl_location_name"
                            track-by="hl_location_id" tag-placeholder="Add this as new location"
                            placeholder="Search or add a location" :multiple="true" :disabled="disableLocation" :close-on-select="false">
                        </VueMultiselect>
                        <p v-if="errors.location" class="text-danger" style="font-size: 12px">{{ errors.location }}</p>

                        <div class="d-flex justify-content-center align-items-center mt-4" v-if="userId">
                            <argon-button variant="gradient" color="danger" @click="showResetModal()">
                                Reset Password
                            </argon-button>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <Teleport to="body">
            <!-- use the modal component, pass in the prop -->
            <modal :show="showModal" @close="showModal = false">
                <template #header>
                    <h4>Reset Password</h4>
                </template>
                <template #body>
                    <p class="text-danger" v-if="password_error_msg">{{ password_error_msg }}</p>
                    <label>Password</label>
                    <argon-input placeholder="Password" id="reset_password" type="password"></argon-input>
                    <label>Confirm Password</label>
                    <argon-input placeholder="Confirm Password" id="reset_confirm_password" type="password">
                    </argon-input>
                </template>
                <template #footer>
                    <argon-button @click="showModal=false" color="danger">Cancel</argon-button>
                    <argon-button @click="resetPassword()">Reset</argon-button>
                </template>
            </modal>
        </Teleport>
        <Teleport to="body">
            <div class="loading-mask" v-if="saving">
                <div class="loader">Loading...</div>
            </div>
        </Teleport>
    </div>
</template>
  
<script>
import { useStore } from "vuex";
import { computed, toRaw, ref } from "vue";
import { useRouter } from 'vue-router';
import { auth, db, storage, functions } from '../firebaseConfig'
import Navbar from "@/components/Navbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Sidebar from '@/components/Sidebar.vue';
import Modal from '@/components/Modal.vue';
import { query, collection, getDocs, where, setDoc, doc, deleteDoc } from '@firebase/firestore';
import { ref as FileRef, uploadBytes, getDownloadURL } from '@firebase/storage';
import { httpsCallable } from "@firebase/functions";
import VueMultiselect from 'vue-multiselect'

const body = document.getElementsByTagName("body")[0];
export default {
    name: "UserManageComponent",
    components: {
        Navbar,
        ArgonButton,
        ArgonInput,
        Sidebar,
        VueMultiselect,
        Modal,
    },
    data() {
        return {
            profile: null,
            roles: [],
            locations: toRaw([]),
            selectedLocation: [],
            tempData: [],
            isOver: false,
            imageData: null,
            saving: false,
            showModal: false,
            userId: null,
            password_error_msg: '',
            errors: {
                email: null,
                name: null,
                role: null,
                location: null,
                password: null,
            },
            disableLocation: false,
        }
    },
    setup() {
        let campaign_type = ref('Client')
        let visibleSidebar = ref(false)

        const store = useStore()
        const router = useRouter();

        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });

        const user = computed(() => {
            return store.getters.user;
        });

        const changeType = (c_type) => {
            campaign_type.value = c_type;
        }

        const toggleSidebar = () => {
            visibleSidebar.value = !visibleSidebar.value;
        }

        const moveToBack = () => {
            router.push({ name: 'Users' });
        }

        return { user, changeType, campaign_type, toggleSidebar, visibleSidebar, moveToBack }
    },
    methods: {
        getRoles: async function () {
            const q = query(collection(db, 'roles'));
            const querySnap = await getDocs(q);
            let roleAry = [];
            querySnap.forEach((snap) => {
                roleAry.push({
                    id: snap.id,
                    ...snap.data()
                });
            });
            this.roles = roleAry;
            return roleAry;
        },
        getUserInfo: async function () {
            this.saving = true;
            const q = query(collection(db, 'profiles'), where('user_id', '==', this.$route.params.userId));
            const querySnap = await getDocs(q);
            let profile = null;
            for (let i = 0; i < querySnap.size; i++) {
                profile = querySnap.docs[i].data();
                const role_q = query(collection(db, 'user_roles'), where('user_id', '==', this.$route.params.userId));
                const roleQuerySnap = await getDocs(role_q);

                if (roleQuerySnap.docs[0]) {
                    const user_role = roleQuerySnap.docs[0].data();
                    profile.role_id = user_role.role_id;
                    
                    const selected_role = this.roles.find(r => r.id == user_role.role_id);
                    if (selected_role.name == 'Admin' || selected_role.name == 'Super Admin') {
                        this.disableLocation = true;
                    } else {
                        this.disableLocation = false;
                    }
                } else {
                    profile.role_id = '';
                }

                const location_q = query(collection(db, 'user_locations'), where('user_id', '==', this.$route.params.userId));
                const locationQuerySnap = await getDocs(location_q);
                locationQuerySnap.docs.forEach(loc => {
                    const locationData = loc.data();
                    const location = this.locations.find(item => item?.hl_location_id == locationData.location_id);
                    if (location) {
                        this.selectedLocation.push(location);
                    }
                });
            }
            this.profile = profile;
            this.saving = false;
            return profile;
        },
        getLocations: async function () {
            const res = await this.axios.get('/LocationsList');
            if (this.locations.length == 0) {
                this.locations = res?.data?.locations || [];
            }
        },
        imageChange: async function () {
            const imageData = body.querySelector('#avatar_file').files[0];
            this.imageData = imageData;
            this.profile = {
                ...this.profile,
                avatar: URL.createObjectURL(imageData),
            }
        },
        changeRole: function () {
            const role = document.querySelector('#role').value;
            const selected_role = this.roles.find(r => r.id == role);
            if (selected_role.name == 'Admin' || selected_role.name == 'Super Admin') {
                this.disableLocation = true;
            } else {
                this.disableLocation = false;
            }
        },
        formValidate: function () {
            const role = document.querySelector('#role').value;
            if (!body.querySelector('#user_email').value) {
                this.errors.email = "Email can't be blank";
            }
            if (!body.querySelector('#name').value) {
                this.errors.name = "User Name can't be blank";
            }

            if (role != 'Admin' && role != 'Super Admin') {
                if (!this.selectedLocation.length) {
                    this.errors.location = "You have to select at least a location";
                }
            }
        },
        createUser: async function () {
            this.formValidate();
            if (this.$route.params && this.$route.params.userId) {
                this.updateUser();
                return;
            }
            if (!body.querySelector('#user_password').value) {
                this.errors.password = "Password can't be blank";
                return;
            }
            if (body.querySelector('#user_password').value.length < 6) {
                this.errors.name = "You have to enter at least 6 digit!";
                return;
            }
            const selectedLocations = this.selectedLocation;
            const addUser = httpsCallable(functions, 'createUser');
            this.saving = true;
            this.imageData = body.querySelector('#avatar_file').files[0];

            addUser({
                email: body.querySelector('#user_email').value,
                password: body.querySelector('#user_password').value,
                displayName: body.querySelector('#name').value,
            })
                .then(res => {
                    if (res) {
                        if (this.imageData) {
                            const storageRef = FileRef(storage, `${this.imageData.name}`);
                            uploadBytes(storageRef, this.imageData).then(async (snapshot) => {
                                console.log('Uploaded a blob or file!', snapshot);
                                const image_url = await getDownloadURL(storageRef);

                                setDoc(doc(collection(db, 'profiles')), {
                                    full_name: body.querySelector('#name').value,
                                    email: body.querySelector('#user_email').value,
                                    avatar: image_url,
                                    user_id: res.data.response.uid
                                });
                            });
                        } else {
                            setDoc(doc(collection(db, 'profiles')), {
                                full_name: body.querySelector('#name').value,
                                email: body.querySelector('#user_email').value,
                                avatar: '',
                                user_id: res.data.response.uid
                            });
                        }
                        setDoc(doc(collection(db, 'user_roles')), {
                            role_id: body.querySelector('#role').value,
                            user_id: res.data.response.uid
                        });

                        for (let i = 0; i < selectedLocations.length; i++) {
                            const loc = selectedLocations[i];
                            setDoc(doc(collection(db, 'user_locations')), {
                                location_id: loc.hl_location_id,
                                user_id: res.data.response.uid
                            });
                        }
                        this.saving = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.saving = false;
                });
        },
        updateUser: async function () {
            const editUser = httpsCallable(functions, 'updateUser');
            this.saving = true;
            const selectedLocations = this.selectedLocation;
            const userId = this.$route.params.userId;
            const imageData = this.imageData;
            editUser({
                email: body.querySelector('#user_email').value,
                displayName: body.querySelector('#name').value,
                uid: userId,
            })
                .then(async (res) => {
                    if (res) {
                        const profile_q = query(
                            collection(db, "profiles"),
                            where("user_id", "==", userId)
                        );
                        const profilesQuerySnap = await getDocs(profile_q);
                        profilesQuerySnap.docs.forEach(p => {
                            if (imageData) {
                                const storageRef = FileRef(storage, `${imageData.name}`);
                                uploadBytes(storageRef, imageData).then(async (snapshot) => {
                                    console.log('Uploaded a blob or file!', snapshot);
                                    const image_url = await getDownloadURL(storageRef);

                                    setDoc(p.ref, {
                                        full_name: body.querySelector('#name').value,
                                        email: body.querySelector('#user_email').value,
                                        avatar: image_url,
                                        user_id: userId
                                    });
                                });
                            } else {
                                setDoc(p.ref, {
                                    full_name: body.querySelector('#name').value,
                                    email: body.querySelector('#user_email').value,
                                    user_id: userId
                                })
                            }
                        });
                        const roles_q = query(
                            collection(db, "user_roles"),
                            where("user_id", "==", userId)
                        );
                        const rolesQuerySnap = await getDocs(roles_q);
                        rolesQuerySnap.docs.forEach(r => {
                            setDoc(r.ref, {
                                role_id: body.querySelector('#role').value,
                                user_id: userId
                            })
                        });

                        const locations_q = query(
                            collection(db, "user_locations"),
                            where("user_id", "==", userId)
                        );
                        const locationsQuerySnap = await getDocs(locations_q);
                        locationsQuerySnap.docs.forEach(l => {
                            deleteDoc(l.ref);
                        });

                        for (let i = 0; i < selectedLocations.length; i++) {
                            const loc = selectedLocations[i];

                            setDoc(doc(collection(db, 'user_locations')), {
                                location_id: loc.hl_location_id,
                                user_id: userId
                            });
                        }
                        this.saving = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.saving = false;
                });
        },
        showResetModal: function () {
            this.showModal = true;
        },
        resetPassword: function () {
            if (!body.querySelector('#reset_password').value) {
                this.password_error_msg = 'Password can\'t be blank!';
                return;
            }
            if (body.querySelector('#reset_password').value.length < 6) {
                this.password_error_msg = 'You have to enter at least 6 digit!';
                return;
            }
            if (body.querySelector('#reset_password').value != body.querySelector('#reset_confirm_password').value) {
                this.password_error_msg = 'Confirm password doesn\'t match New password';
                return;
            }
            const editUser = httpsCallable(functions, 'updateUser');
            const userId = this.$route.params.userId;
            editUser({
                password: body.querySelector('#reset_password').value,
                uid: userId,
            })
                .then(res => {
                    if (res) {
                        this.showModal = false;
                    }
                })
        }
    },
    mounted() {
        this.getRoles();
        this.getLocations();
        if (this.$route.params && this.$route.params.userId) {
            this.userId = this.$route.params.userId;
            this.getUserInfo();
        }
    },
};
</script>