import { createRouter, createWebHistory } from "vue-router";
import { auth } from "../firebaseConfig";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import UserManage from "../views/UserManage.vue";
import AddUser from "../views/AddUser.vue";
import FulfilDashboard from "../views/FulfilDashboard.vue";
import AgentDashboard from "../views/AgentDashboard.vue";
import CampaignDashboard from "../views/CampaignDashboard.vue";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  console.log("current user in auth guard: ", user);
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const checkAuthed = (to, from, next) => {
  let user = auth.currentUser;
  if (user) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: checkAuthed,
  },

  {
    path: "/fulfillment",
    name: "FulfilDashboard",
    component: FulfilDashboard,
    beforeEnter: requireAuth,
  },

  {
    path: "/agentdashboard",
    name: "AgentDashboard",
    component: AgentDashboard,
    beforeEnter: requireAuth,
  },
  {
    path: "/campaign",
    name: "CampaignDashboard",
    component: CampaignDashboard,
    beforeEnter: requireAuth,
  },

  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: requireAuth,
  },

  {
    path: "/users",
    name: "Users",
    component: UserManage,
    beforeEnter: requireAuth,
  },
  {
    path: "/add",
    name: "AddUser",
    component: AddUser,
    beforeEnter: requireAuth,
  },
  {
    path: "/edit/:userId",
    name: "EditUser",
    component: AddUser,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
