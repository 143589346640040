<template>
  <div id="dashboard">
    <div class="container-fluid top-0 position-sticky z-index-sticky header">
      <div class="row">
        <div class="col-12">
          <navbar
            isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            v-bind:darkMode="true"
            isBtn="bg-gradient-success"
            :user="user"
            :changeAgent="changeAgent"
            :showSidebar="toggleSidebar"
            :headersDropdown="false"
            :showAgentDropdown="true"
          />
        </div>
      </div>
    </div>
    <div
      class="sidebar-container"
      :class="visibleSidebar ? 'visible-sidebar' : 'hide-sidebar'"
    >
      <div class="sidebar-mask" @click="toggleSidebar()"></div>
      <sidebar :user="user" />
    </div>
    <div class="container">
      <div
        class="d-flex justify-content-center mt-3"
      >
        <p class="text-white dashboard-greetings">
          <strong>Agent Performance Dashboard</strong>
        </p>
      </div>
      <div class="main-content px-4 mt-4">
        <div class="d-flex justify-content-between align-items-center px-2">
          <h4 class="text-white">Status</h4>
          <select class="form-control period-select" v-on:change="applyDateFilter($event.target.value);">
            <option value="all">All Time</option>
            <option value="30">30 Days</option>
            <option value="60">60 Days</option>
            <option value="90">90 Days</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <div class="d-flex ms-auto w-100 mt-2 px-0 justify-content-between period-select me-2" v-if="showCustomDateSelect">
          <div class="form-group col-5">
            <label for="" class="text-white" >Start Date</label>
            <input type="date" :value="start_date" v-on:change="start_date = moment($event.target.value).format('YYYY-MM-DD');" class="form-control period-select">
          </div>
          <div class="form-group col-5" >
            <label for="" class="text-white">End Date</label>
            <input type="date" :class="start_date == '' ? 'opacity-25':''" :disabled="start_date == ''"  :value="end_date" v-on:change="end_date = moment($event.target.value).format('YYYY-MM-DD'); getAgentDashboardCount()" class="form-control period-select">
          </div>
        </div>
        <main class="mt-3 d-flex flex-wrap align-items-stretch">
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-primary dashboard-value-card"
              style="background: rgba(212, 13, 255, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Retainers:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/users.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo users"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">{{ dashboardCount?.retainers_count || 0 }}</h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-pirmary dashboard-value-card"
              style="background: rgba(116, 81, 241, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">DQ:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/dislike.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">{{ dashboardCount?.disqualified_count || 0 }}</h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(56, 54, 160, 0.8);"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Docs Sent:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/calendar.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">{{ dashboardCount?.docs_sent_count || 0 }}</h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(56, 54, 160, 0.8);"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Total Messages Sent</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/send.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">{{ dashboardCount?.text_output_count || 0 }}</h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(0, 0, 0, 0.5)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">
                      Total Contacts Made:
                    </p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/group.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">{{ dashboardCount?.contacts_made_count || 0 }}</h3>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { auth } from "../firebaseConfig";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import moment from 'moment'
import 'moment-timezone';

export default {
  name: "AgentDashboardComponent",
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      selectedAgentId: "",
      dashboardCount: [],
      locations: [],
      start_date: '',
      end_date: '',
      dateFilter:'',
      showCustomDateSelect:false,
      loading: true,
    };
  },
  setup() {
    let visibleSidebar = ref(false);

    const store = useStore();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const toggleSidebar = () => {
      visibleSidebar.value = !visibleSidebar.value;
    };

    return { user, toggleSidebar, visibleSidebar };
  },
  methods: {
    getAgentDashboardCount: async function () {
      this.loading = true;
      let params = '';
      if (this.selectedAgentId) {
        params += 'agent_id=' + this.selectedAgentId;
      }
      if (this.start_date != "" && this.end_date != "")
        params = params + `&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.axios
        .get("/AgentDashboardCounts?" + params)
        .then(async (res) => {
          this.loading = false;
          this.dashboardCount = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    applyDateFilter: async function (event) {
      console.log(event)
      this.showCustomDateSelect = false;
      this.dateFilter = event;
      if (event == 'all') {
        this.start_date = '';
        this.end_date = '';
        this.getAgentDashboardCount();
      } else if (event == '30') {
        this.start_date = moment(new Date()).subtract(30, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.getAgentDashboardCount();
      } else if (event == '60') {
        this.start_date = moment(new Date()).subtract(60, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.getAgentDashboardCount();
      } else if (event == '90') {
        this.start_date = moment(new Date()).subtract(90, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.getAgentDashboardCount();
      } else if (event == 'custom') {
        this.showCustomDateSelect = true;
        this.start_date = '';
        this.end_date = '';
      }
    },
    changeAgent: function(agentId) {
      this.selectedAgentId = agentId;
      this.getAgentDashboardCount();
    },
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
