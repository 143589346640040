<template>
  <div class="sidebar">
    <nav
      class="navbar navbar-main navbar-expand-lg px-0 shadow-none sidebarnav"
    >
      <div class="px-3 py-1 container-fluid d-flex justify-content-center">
        <img src="../assets/img/logo.svg" style="height: 58px" />
      </div>
    </nav>
    <div class="sidebar-content">
      <div class="profile-content">
        <div class="sidebar-avatar">
          <div class="avatar-image">
            <img
              src="../assets/img/non-user.webp"
              style="width: 200px; height: 200px"
            />
          </div>
        </div>
        <div class="avatar-name">
          <p>{{ userInfo?.displayName || "" }}</p>
        </div>

        <button
          v-if="
            userInfo?.role?.name == 'User' ||
            userInfo?.role?.name == 'Agency' ||
            userInfo?.role?.name == 'Admin' ||
            userInfo?.role?.name == 'Super Admin'
          "
          class="user-management-btn"
          @click="moveToDashboard()"
        >
          <div>
            Client Performance
          </div>
        </button>
        <button
          v-if="
            userInfo?.role?.name == 'Agency' ||
            userInfo?.role?.name == 'Admin' ||
            userInfo?.role?.name == 'Super Admin'
          "
          class="user-management-btn"
          @click="moveToCampaignDashboard()"
        >
          <div>
            Campaign Performance
          </div>
        </button>
        <button
          v-if="
            userInfo?.role?.name == 'Agent' ||
            userInfo?.role?.name == 'Admin' ||
            userInfo?.role?.name == 'Super Admin'
          "
          class="user-management-btn"
          @click="moveToFulfileDashboard()"
        >
          <div>
            Fulfillment Dashboard
          </div>
        </button>
        <button
          v-if="
            userInfo?.role?.name == 'Admin' ||
            userInfo?.role?.name == 'Super Admin'
          "
          class="user-management-btn"
          @click="moveToAgentDashboard()"
        >
          <div>
            Agent Performance Dashboard
          </div>
        </button>
        <button
          v-if="
            userInfo?.role?.name == 'Agency' ||
            userInfo?.role?.name == 'Admin' ||
            userInfo?.role?.name == 'Super Admin'
          "
          class="user-management-btn"
          @click="moveToUserManage()"
        >
          <div>
            User Management
          </div>
        </button>
      </div>
      <div class="action-content mt-4">
        <button class="login-btn">
          <div
            class="d-flex justify-content-center align-items-center"
            @click="signOut()"
          >
            <img src="../assets/img/logout.svg" />
            <p class="mb-0 ms-md-1">Logout</p>
          </div>
        </button>
        <!-- <button class="report-btn" @click="moveToUserManage()">Report Dashboard</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Sidebar",
  components: {},
  props: {
    show: Boolean,
    user: Object,
  },
  computed: {
    userInfo() {
      return this.user.data;
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const signOut = async () => {
      await store.dispatch("logOut");
      router.push({ name: "Login" });
    };

    const moveToUserManage = () => {
      router.push({ name: "Users" });
    };
    const moveToDashboard = async (event) => {
      router.push({ name: 'Dashboard'});
      await store.dispatch('getView',  {view :event});
    }

    const moveToFulfileDashboard = () => {
      router.push({ name: "FulfilDashboard" });
    };

    const moveToAgentDashboard = () => {
      router.push({ name: "AgentDashboard" });
    };

    const moveToCampaignDashboard = () => {
      router.push({ name: "CampaignDashboard" });
    };

    return {
      signOut,
      moveToUserManage,
      moveToDashboard,
      moveToFulfileDashboard,
      moveToAgentDashboard,
      moveToCampaignDashboard,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
