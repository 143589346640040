<template>
  <div id="dashboard">
    <div class="container-fluid top-0 position-sticky z-index-sticky header">
      <div class="row">
        <div class="col-12">
          <navbar
            isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            v-bind:darkMode="true"
            isBtn="bg-gradient-success"
            :user="user"
            :changeLocation="changeLocation"
            :changeType="changeType"
            :showSidebar="toggleSidebar"
            :headersDropdown="true"
          />
        </div>
      </div>
    </div>
    <div
      class="sidebar-container"
      :class="visibleSidebar ? 'visible-sidebar' : 'hide-sidebar'"
    >
      <div class="sidebar-mask" @click="toggleSidebar()"></div>
      <sidebar :user="user" :changeLocation="changeLocation" />
    </div>

    <div class="container">
      <div
        class="d-flex justify-content-center mt-3"
        v-if="campaign_type == 'Client'"
      >
        <p class="text-white dashboard-greetings">
          {{ greeting }}, <strong>{{ user?.data?.displayName }}</strong>
        </p>
      </div>
      <div class="main-content px-4 mt-4">
        <div class="d-flex justify-content-between align-items-center px-2">
          <h4 class="text-white">Status</h4>
          <select class="form-control period-select" v-on:change="applyDateFilter($event.target.value);">
            <option value="all">All Time</option>
            <option value="30">30 Days</option>
            <option value="60">60 Days</option>
            <option value="90">90 Days</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <div class="d-flex ms-auto w-100 mt-2 px-0 justify-content-between period-select me-2" v-if="showCustomDateSelect">
          <div class="form-group col-5">
            <label for="" class="text-white" >Start Date</label>
            <input type="date" :value="start_date" v-on:change="start_date = moment($event.target.value).format('YYYY-MM-DD');" class="form-control period-select">
          </div>
          <div class="form-group col-5" >
            <label for="" class="text-white">End Date</label>
            <input type="date" :class="start_date == '' ? 'opacity-25':''" :disabled="start_date == ''"  :value="end_date" v-on:change="end_date = moment($event.target.value).format('YYYY-MM-DD'); getClientDashboardCounts(); getContacts()" class="form-control period-select">
          </div>
        </div>
        <main class="mt-3 d-flex flex-wrap align-items-stretch">
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-primary dashboard-value-card"
              style="background: rgba(212, 13, 255, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Leads:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/users.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo users"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.leads_count || 0 }}
                </h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-pirmary dashboard-value-card"
              style="background: rgba(169, 0, 228, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Live Transfers:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/call.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.live_transfers_count || 0 }}
                </h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-primary dashboard-value-card"
              style="background: rgba(116, 81, 241, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Retainers:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/group.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.retainers_count || 0 }}
                </h3>
              </div>
            </div>
          </div>
          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(56, 54, 160, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">
                      Sent Documents to Client:
                    </p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/send.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.docs_sent_count || 0 }}
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(56, 54, 160, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">
                      Booked Appointment:
                    </p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/calendar.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.appt_booked_count || 0 }}
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(0, 0, 0, 0.4)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Disqualified:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/dislike.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.disqualified_count || 0 }}
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(212, 241, 0, 0.8)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">Response Rate:</p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/arrow-left.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.response_rate || 0 }}%
                </h3>
              </div>
            </div>
          </div>

          <div class="p-2 col-xl-3 col-lg-3 col-md-3 d-flex flex-column">
            <div
              class="card card-danger dashboard-value-card"
              style="background: rgba(0, 0, 0, 0.5)"
            >
              <div
                style="padding: 25.35px 46.22px; height: 100%"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-1">
                    <p class="text-white font-weight-bold">
                      Medical Review Received:
                    </p>
                  </div>
                  <img
                    src="../assets/img/icons/dashboard/medical-record.svg"
                    class="avatar avatar-sm mx-1"
                    alt="logo cross"
                  />
                </div>
                <div class="small-loader" v-if="loading">Loading...</div>
                <h3 class="mt-0 text-white card-value" v-if="!loading">
                  {{ clientDashboardCounts.medical_record_leads_coun || 0 }}
                </h3>
              </div>
            </div>
          </div>
        </main>
        <div class="p-2 mt-3">
          <div
            class="d-flex justify-content-end align-items-center contact-btns"
          >
            <argon-button
              variant="gradient"
              class="download-btn"
              color="success"
              @click="download(false)"
              :disabled="generateCsvData"
            >
              {{ generateCsvData ? "Downloading..." : "Download All" }}
            </argon-button>
            <argon-button
              variant="gradient"
              color="success"
              @click="download(true)"
              :disabled="generateRetainedCsvData"
              class="ms-md-3 download-btn"
              >{{
                generateRetainedCsvData ? "Downloading..." : " Download Retained"
              }}
            </argon-button>
          </div>
          <div class="card card-primary custom-card table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-white text-center">Id</th>
                  <th
                    class="text-white text-center"
                    v-on:click="sort('hl_first_name')"
                  >
                    First Name
                  </th>
                  <th
                    class="text-white text-center"
                    v-on:click="sort('hl_last_name')"
                  >
                    Last Name
                  </th>
                  <th
                    class="text-white text-center"
                    v-on:click="sort('hl_contact_email')"
                  >
                    Email Address
                  </th>
                  <th
                    class="text-white text-center"
                    v-on:click="sort('hl_contact_email')"
                  >
                    Phone Number
                  </th>
                  <th
                    class="text-white text-center"
                    v-on:click="sort('hl_contact_stage')"
                  >
                    Stage
                  </th>
                  <th
                    class="text-white text-center"
                    v-on:click="sort('hl_contact_date_added')"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="isloadingContacts">
                  <td colspan="8" class="text-white text-center">Loading...</td>
                </tr>
                <template v-if="!isloadingContacts">
                  <tr v-for="contact of contacts" :key="contact.id">
                  <td stye class="text-white text-center">{{contact.id}}</td>
                  <td class="text-white text-center">{{contact.hl_first_name}}</td>
                  <td class="text-white text-center">{{contact.hl_last_name}}</td>
                  <td class="text-white text-center">{{contact.hl_contact_email}}</td>
                  <td class="text-white text-center">{{contact.hl_contact_phone}}</td>
                  <td class="text-white text-center">{{contact.hl_contact_stage}}</td>
                  <td class="text-white text-center">{{contact.hl_contact_date_added}}</td>
                </tr>
                </template>
                
              </tbody>
            </table>
          </div>
          <div class="text-center mt-4">
            <vue-awesome-paginate
              :total-items="contactsTotalRecords"
              :items-per-page="per_page"
              :max-pages-shown="5"
              :current-page="page"
              :on-click="paginate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { auth } from "../firebaseConfig";
import Navbar from "@/components/Navbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import moment from 'moment';
import 'moment-timezone';

export default {
  name: "DashboardComponent",
  components: {
    Navbar,
    ArgonButton,
    Sidebar,
  },
  data() {
    return {
      selectedLocationId: "",
      per_page: 20,
      page: 1,
      order_by: "hl_first_name",
      order: "ASC",
      contacts: [],
      locations: [],
      clientDashboardCounts: {},
      contactsTotalRecords: 0,
      generateCsvData:false,
      generateRetainedCsvData:false,
      isloadingContacts:false,
      start_date: '',
      end_date: '',
      dateFilter:'',
      showCustomDateSelect:false,
      loading: true,
    }
  },
  setup() {
    let campaign_type = ref("Client");
    let visibleSidebar = ref(false);
    const scrollComponent = ref(null);

    const store = useStore();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const changeType = (c_type) => {
      campaign_type.value = c_type;
    };

    const toggleSidebar = () => {
      visibleSidebar.value = !visibleSidebar.value;
    };

    return {
      user,
      changeType,
      campaign_type,
      toggleSidebar,
      visibleSidebar,
      scrollComponent,
    };
  },
  methods: {
    getContacts: async function () {
      this.isloadingContacts =true;
      this.axios
        .get(
          `/ContactsList?location_id=${this.selectedLocationId}&per_page=${this.per_page}&page=${this.page}&order_by=${this.order_by}&order=${this.order}&start_date=${this.start_date}&end_date=${this.end_date}`
        )
        .then(async (res) => {
          this.contacts = res?.data?.contacts;
          this.contactsTotalRecords = res?.data?.total_contacts_count;
          
          this.isloadingContacts = false;
        });
    },
    getClientDashboardCounts: async function () {
      this.loading = true;
      let params = '?';
      if (this.selectedLocationId) {
        params += `location_id=${this.selectedLocationId}&`;
      }
      if (this.start_date) {
        params += `start_date=${this.start_date}&`;
      }
      if (this.end_date) {
        params += `end_date=${this.end_date}`;
      }
      this.axios
        .get(
          "/ClientDashboardCounts" + params
        )
        .then(async (res) => {
          this.loading = false;
          this.clientDashboardCounts = res?.data;
          console.log("ClientDashboardCounts", res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sort: async function (event) {
      if (this.order_by == event) {
        if (this.order == "ASC") {
          this.order = "DESC";
        } else {
          this.order = "ASC";
        }
      } else {
        this.order = "ASC";
      }
      this.order_by = event;
      this.page = 1;
      this.contactsTotalRecords = 0;
      this.paginate(1);
    },
    paginate: async function (event) {
      this.page = event;
      await this.getContacts();
    },
    convertToCSV: async function (objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";
      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";
          line += await array[i][index];
        }
        str += line + "\r\n";
      }

      return str;
    },
    exportCSVFile: async function (headers = null, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }
      // Convert Object to JSON
      var jsonObject = await JSON.stringify(items);
      var csv = await this.convertToCSV(jsonObject);
      var exportedFilenmae = fileTitle + ".csv" || "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    download: async function (retain) {
      let api = '';
      if(retain) {
        api =`/ContactsList?location_id=${this.selectedLocationId}&per_page=all&stage=Retain`;
        this.generateRetainedCsvData = true;
      } else {
        api =`/ContactsList?location_id=${this.selectedLocationId}&per_page=all&order_by=hl_first_name&order=ASC`;
        this.generateCsvData = true;
      }
      let csvData = [];
      await this.axios.get(api).then(async (res) => {
        csvData = res?.data?.contacts || [];
        csvData = csvData.map(item => ({
          id: item.id,
          hl_first_name: item.hl_first_name,
          hl_last_name: item.hl_last_name,
          hl_contact_email: item.hl_contact_email,
          hl_contact_phone: item.hl_contact_phone,
          hl_contact_stage: item.hl_contact_stage,
          hl_contact_date_added: item.hl_contact_date_added,
        }))
      });

      var headers = ['ID', 'First Name', 'Last Name', 'Email', 'Phone', 'Status', 'Date Added'];
      var itemsFormatted = csvData;

      var fileTitle = (!retain ? 'All ' : 'Retained ') + "Contacts"; // or 'my-unique-title'

      await this.exportCSVFile(headers, itemsFormatted, fileTitle);
      this.generateCsvData = false;
      this.generateRetainedCsvData = false;
    },
    changeLocation: async function (loc_type) {
      console.log(loc_type, this.selectedLocationId);
      this.selectedLocationId = loc_type;
      this.getClientDashboardCounts();
      this.getContacts();
    },
    applyDateFilter: async function (event) {
      console.log(event)
      this.showCustomDateSelect = false;
      this.dateFilter = event;
      if (event == 'all') {
        this.start_date = '';
        this.end_date = '';
        this.getClientDashboardCounts();
        this.getContacts()
      } else if (event == '30') {
        this.start_date = moment(new Date()).subtract(30, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.getClientDashboardCounts();
        this.getContacts()
      } else if (event == '60') {
        this.start_date = moment(new Date()).subtract(60, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.getClientDashboardCounts();
        this.getContacts()
      } else if (event == '90') {
        this.start_date = moment(new Date()).subtract(90, "days").format('YYYY-MM-DD');
        this.end_date = moment(new Date()).format('YYYY-MM-DD');
        this.getClientDashboardCounts();
        this.getContacts()
      } else if (event == 'custom') {
        this.showCustomDateSelect = true;
        this.start_date = '';
        this.end_date = '';
      }
    },
  },
  computed: {
    greeting: function() {
      var today = new Date()
      var curHr = today.getHours()

      let str = '';
      if (curHr >= 0 && curHr < 6) {
        str = 'What are you doing that early?';
      } else if (curHr >= 6 && curHr <= 12) {
        str = 'Good Morning';
      } else if (curHr >= 12 && curHr < 17) {
        str = 'Good Afternoon';
      } else {
        str = 'Good Evening';
      }
      return str;
    }
  },
  watch: {
    "$store.state.view": function () {
      console.log(this.$store.state.view);
      this.campaign_type = this.$store.state.view;
    },
  },
  mounted() {
    this.getContacts();
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
